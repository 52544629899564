import Store from "Helpers/State/Store";

export default new Store({
	state: {
		/**
		 * productSelected: productJson
		 * uploadStatus : {"idle" | "uploading" | "uploaded" | empty }
		 */
		products: [],
		productSelected: null,
		clientID: "",
		orderID: ""
	},
	mutations: {
		setProductSelected(state, productJson) {
			state.productSelected = productJson;
		},
		setUploadStatus(state, statusInfo) {
			state.products.forEach(product => {
				if (product.productId == statusInfo.productId) {
					if (statusInfo.type == "receita") {
						product.uploadStatus.receita = statusInfo.status;
					} else {
						product.uploadStatus.medicao = statusInfo.status;
					}
				}
			});
			state.products = state.products;
		},
		setClientId(state, clientId) {
			state.clientID = clientId;
		},
		setOrderId(state, orderId) {
			state.orderID = orderId;
		},
		addProduct(state, product) {
			state.products = [...state.products, product];
		}
	},
	actions: {
		async uploadFile(store, e) {
			const form = $(e.target).parents("form");
			const inputFile = form.find("input[type=file]");
			const fileType = form.data("form");
			const statusInfo = {
				productId: inputFile.data("id"),
				type: fileType,
				status: "uploading"
			};

			if (!inputFile[0].files.length) {
				statusInfo.status = "empty";
				store.commit("setUploadStatus", statusInfo);
				return;
			}

			let nameFile = inputFile.val().toLowerCase(),
				extensionValidator = new RegExp(
					"(.*?).(png|jpg|jpeg|pdf|bmp|tif|tiff)$"
				);
			if (!extensionValidator.test(nameFile)) {
				statusInfo.status = "invalid-type";
				store.commit("setUploadStatus", statusInfo);
				return;
			}

			store.commit("setUploadStatus", statusInfo);

			const receitaSearch = {
				_fields: "id",
				_where: `(orderID=${store.state.orderID}) AND (skuID=${store.state.productSelected.productId})`
			};

			const [receitaExistsPromise] = store.events.publish(
				"verifyReceitaExists",
				receitaSearch
			);

			const receitaExists = await receitaExistsPromise;

			if (receitaExists.length == 0) {
				const receitaData = {
					clientID: store.state.clientID,
					orderID: store.state.orderID,
					skuID: store.state.productSelected.productId,
					product: store.state.productSelected.productName
				};

				const [receitaPromise] = store.events.publish(
					"createReceita",
					receitaData
				);

				const receita = await receitaPromise;
				var receitaId = receita.DocumentId;
			} else {
				var receitaId = receitaExists[0].id;
			}

			const uploadData = {
				id: receitaId,
				field: fileType,
				file: inputFile.val(),
				form: form
			};

			const [fileUploadPromise] = store.events.publish(
				"fileUpload",
				uploadData
			);

			const fileUpload = await fileUploadPromise;

			if (fileUpload.ok) {
				statusInfo.status = "uploaded";
			} else {
				statusInfo.status = "error";
			}
			store.commit("setUploadStatus", statusInfo);
		}
	}
});
