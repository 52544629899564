import { alterarTamanhoImagemSrcVtex } from "Helpers/vtexUtils";
import { CHANGE_IMAGE_HIGHLIGHT, CHANGE_IMAGE_LIST } from "./EventType";

export default class ImageThumbs {
	constructor(store, container, options) {
		this._store = store;
		this._configurar(options);

		this.desenhar(container);

		this.eventos();
	}
	desenhar(container) {
		let _html = `<div class="images__list loading">
			<div class="text">Carregando...</div>
		</div>`;

		this._elemento = $(_html);
		this._elemento.appendTo(container);
	}

	eventos() {
		this._store.events.subscribe(
			CHANGE_IMAGE_LIST,
			this.atualizarLista.bind(this)
		);

		this._elemento.on("click", ".images__item img", event => {
			let index = event.target.dataset.index;
			this._store.events.publish(
				CHANGE_IMAGE_HIGHLIGHT,
				this.lista[index]
			);
		});
	}

	atualizarLista(event, lista) {
		this.lista = lista.map(image => {
			image.src = alterarTamanhoImagemSrcVtex(
				image.src,
				this._options.width,
				this._options.height
			);
			return image;
		});
		let _html = this.buildThumbs(this.lista);
		this._elemento.html(_html);

		// ativa slide
		this._elemento.find("ul.images__thumb").slick(this._options.slide);
	}
	_configurar(options) {
		// defaults
		this._options = {
			width: 80,
			height: 80,
			slide: {
				autoplay: false,
				autoplaySpeed: 3000,
				lazyLoad: "ondemand",
				arrows: false,
				slidesToShow: 7,
				swipeToSlide: true,
				vertical: true,
				verticalSwiping: false,
				infinite: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: true,
							dots: true
						}
					}
				]
			}
		};
		Object.assign(this._options, options);
	}

	buildThumbs(lista) {
		return `<ul class="images__thumb" >
			${lista
				.map(
					(img, index) =>
						`<li class="images__item">
					<img src="${img.src}" title="${img.label}" data-index=${index}
					 width="${this._options.width}px" height="${this._options.height}px" />
				</li>`
				)
				.join("")}
		</ul>`;
	}
}
