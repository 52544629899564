export default class Login {
	constructor() {
		this.linkUserHeader();
		this.getUSerData();
	}

	identificacaoDoUsuario(user) {
		var identificacao = user.Email;
		if (user.FirstName !== null) {
			identificacao = user.FirstName;
			console.log(identificacao);
			if (user.LastName !== null) {
				identificacao += " " + user.LastName;
			}
		}
		return identificacao;
	}

	urlLogin() {
		return (
			"/login?ReturnUrl=" + encodeURIComponent(window.location.pathname)
		);
	}

	urlLogout() {
		return (
			"/no-cache/user/logout?ReturnUrl=" +
			encodeURIComponent(window.location.pathname)
		);
	}

	linkUserHeader() {
		$(document).on(
			"vtex-user-identification",
			function(event, data) {
				const $msgBemVindo = $(".ul-user-links .bem-vindo");
				const $linkEntrar = $(".ul-user-links .link-entrar");

				if (data.IsUserDefined) {
					$linkEntrar.attr("href", "/logout").text("Sair");
					if (data.FirstName) {
						$msgBemVindo.text(`Bem Vindo, ${data.FirstName}`);
					}
				}
			}.bind(this)
		);
	}

	getUSerData() {
		$.getJSON("/no-cache/profileSystem/getProfile").done(function(data) {
			$(document).trigger("vtex-user-identification", data);
		});
	}
}
