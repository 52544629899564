import { ImageStore } from "./ImageStore";
import { CHANGE_SKU } from "Lib/ComponentesProduto/EventType";
import { CHANGE_IMAGE_HIGHLIGHT, CHANGE_IMAGE_LIST } from "./EventType";
import ImageHighlight from "./ImageHighlight";
import ImageThumbs from "./ImageThumbs";
import { isSmallerThen768 } from "Helpers/MediasMatch";

export default class ImageMananger {
	constructor(productStore, options) {
		this._productStore = productStore;
		this._configurar(options);
		this.imagesPerSku = {};

		let initImages = this.getInitImages();
		this._eventoDeAtualziacaoDeImagens(initImages);
		this.events();
	}

	events() {
		this._productStore.events.subscribe(
			"stateChange:selectedItem",
			this.changeImgs.bind(this)
		);
		this._productStore.events.subscribe(
			CHANGE_SKU,
			this.changeImgsTecido.bind(this)
		);
	}
	changeImgs(events, selectedItem) {
		const data = this._productStore.state.selectedItem;
		if (this.imagesPerSku.hasOwnProperty(data.id)) {
			this._eventoDeAtualziacaoDeImagens(this.imagesPerSku[data.id]);
		} else {
			$.ajax({
				method: "GET",
				url: "/produto/sku/" + data.id,
				success: (resposta) => {
					let [sku] = resposta;

					let images = sku.Images.map((item, index) => ({
						src: item[0].Path,
						label: item[0].Name,
						index,
					}));
					this.imagesPerSku[data.id] = images;
					this._eventoDeAtualziacaoDeImagens(
						this.imagesPerSku[data.id]
					);
				},
			});
		}
	}

	changeImgsTecido(events, selectedSku) {
		if (this.imagesPerSku.hasOwnProperty(selectedSku.sku)) {
			this._eventoDeAtualziacaoDeImagens(
				this.imagesPerSku[selectedSku.sku]
			);
		} else {
			$.ajax({
				method: "GET",
				url: "/produto/sku/" + selectedSku.sku,
				success: (resposta) => {
					let [sku] = resposta;
					let images = sku.Images.map((item, index) => ({
						src: item[0].Path,
						label: item[0].Name,
						index,
					}));
					this.imagesPerSku[selectedSku.sku] = images;
					this._eventoDeAtualziacaoDeImagens(
						this.imagesPerSku[selectedSku.sku]
					);
				},
			});
		}
	}

	getInitImages() {
		const images = [];
		$($(this._options.noScriptSelector).text())
			.find(".thumbs img")
			.each((i, el) => {
				images.push({
					src: el.src,
					label: el.alt,
					index: i,
				});
			});

		return images;
	}

	_eventoDeAtualziacaoDeImagens(lista) {
		console.log(lista);
		this._store.events.publish(CHANGE_IMAGE_LIST, lista);
		this._store.events.publish(CHANGE_IMAGE_HIGHLIGHT, lista[0]);
	}

	_configurar(options) {
		// defaults
		this._options = {
			noScriptSelector: "#product-images-vtex",
			containerSelector: ".product__images",
			hasVideo: false,
		};
		Object.assign(this._options, options);

		// store
		this._store = new ImageStore();
		if (isSmallerThen768) {
			new ImageHighlight(this._store, this._options.containerSelector, {
				resumo: true,
			});
		} else {
			new ImageHighlight(this._store, this._options.containerSelector);
			new ImageThumbs(this._store, this._options.containerSelector);
		}
	}
}
