import MasterData from "Helpers/MasterData";

import Components from "Lib/ComponentesProduto";
import { GetImgHover } from "App/components/GetImgHover";
import ImageMananger from "Lib/ProductImages/ImageMananger";
import updateSkusStorage from "Lib/checkout/UpdateSkusStorage";

export default class Product {
	constructor() {
		this.initProductComponents().catch(err => console.error(err));

		this.verificacaoTexto();
		this.events();
		GetImgHover();
		setTimeout(() => {
			this.popup();
			this.altImg();
		}, 3000);
	}

	async initProductComponents() {
		const store = new Components.ComponentStore();
		new ImageMananger(store);
	}

	events() {
		$(".toggle-LimiteDiotria").click(function() {
			$(".productNotification").toggleClass("active");
		});

		$("#caracteristicas > h4").click(function() {
			$(".product-espec").toggleClass("active");
		});

		$(".product-size-table > h4").click(function() {
			$(".product-size-table").toggleClass("active");
		});
	}

	verificacaoTexto() {
		if (!document.querySelector(".group.Especificacoes-Atributos")) {
			$("#caracteristicas h4.group.Caracteristicas").addClass("active");
		}
		if (
			$(".product-description").length > 0 &&
			$(".product-description")[0].innerText == ""
		) {
			$(".product-description").css("display", "none");
		}
	}
	popup() {
		const btnComprar = $(".product .buy-button");
		const popup = $(".product .pop-up");
		const popupcontainer = $(".product .pop-up_container ul .continuar");
		const close = $(".btn-close");

		$(popupcontainer).on("click", () => {
			$(popup).removeClass("ativo");
		});
		$(close).on("click", () => {
			$(popup).removeClass("ativo");
		});
	}
	altImg() {
		const imgs = $(".product .images__figure.zoom img");
		const nameProduct = skuJson.name;
		for (i = 0; i < imgs.length; i++) {
			if (!imgs[i].alt) {
				imgs[i].alt = nameProduct;
				imgs[i].title = nameProduct;
			} else {
				imgs[i].alt = nameProduct;
				imgs[i].title = nameProduct;
			}
		}
	}
}
