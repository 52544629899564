import Page from "./Page";

export default class Container {
	constructor({ appName, components, pages, services }) {
		this.appName = appName;

		this.pageComponents = [...pages];
		this.components = [...components];

		this.services = [...services];
		this.serviceMap = {};

		this.instances = {};
		this.componentsConfig = {};
		this.page = new Page();
	}

	init() {
		this.buildServices.call(this);
		this.buildComponents.call(this);
		this.buildPageComponents.call(this);

		window[this.appName] = this;
	}

	async instantiateComponent(Component) {
		const ctx = {
			getService: this.getService.bind(this),
		};
		if (typeof Component === "function") {
			if (this.componentsConfig[Component.name]) {
				this.instances[Component.name] = new Component(
					ctx,
					this.componentsConfig[Component.name]
				);
			} else {
				this.instances[Component.name] = new Component(ctx);
			}
		} else {
			console.log("Not an page component", Component);
		}
	}

	async instantiateService(Service) {
		if (typeof Service === "function") {
			try {
				this.serviceMap[Service.name] = new Service();
			} catch (error) {
				console.log(error);
			}
		} else {
			console.log("Not an Service", Service);
		}
	}

	getService(serviceName) {
		if (this.serviceMap[serviceName]) return this.serviceMap[serviceName];
		return false;
	}

	buildServices() {
		this.pageComponents.forEach((item) => {
			if (item.hasOwnProperty("services")) {
				if (this.page.is(item.bodyClass)) {
					item.services.forEach((service) =>
						this.services.push(service)
					);
				}
			}
		});

		this.services.forEach(this.instantiateService.bind(this));
	}

	buildComponents() {
		this.components.forEach(this.instantiateComponent.bind(this));
	}

	buildPageComponents() {
		this.pageComponents.forEach((item) => {
			if (this.page.is(item.bodyClass)) {
				for (const i in item.components) {
					if (item.components.hasOwnProperty(i)) {
						const Comp = item.components[i];
						this.instantiateComponent(Comp);
					}
				}
			}
		});
	}

	bind(compName, config) {
		this.componentsConfig[compName] = config;
	}

	start() {
		if (
			document.attachEvent
				? document.readyState === "complete"
				: document.readyState !== "loading"
		) {
			this.init();
		} else {
			document.addEventListener("DOMContentLoaded", this.init.bind(this));
		}
	}
}
