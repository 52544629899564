import { GetImgHover } from "App/components/GetImgHover";

export default class Home {
	constructor() {
		this.selectors();
		$(".helperComplement").remove();
		this.events();
		this.timerBf();
		this.linkBannerBf();
		GetImgHover();
		this.skeletonLoad();
		this.postWord();
		this.slickModelos();
	}

	selectors() {
		this.desktopBanners = $(".home-banner.desktop ul");
		this.MobileBanners = $(".home-banner.mobile ul");
		this.prateleira = $("section.prateleira .prateleira ul");
		this.prateleiraEstilo = $(".estilo__produtos .prateleira ul");
		this.slideEstilo = $(".estilos__estilos-opcoes");

		this.aviador = $("#aviator");
		this.oval = $("#oval");
		this.retangular = $("#retangular");
		this.gatiho = $("#gatinho");
		this.quadrado = $("#quadrado");
		this.redondo = $("#redondo");
	}
	events() {
		this.configureDesktopBannersSlider();
		this.configureMobileBannersSlider();

		this.configureprateleiraSlider();
		this.configureprateleiraEstiloSlider();
		this.configureSlideEstilo();

		this.aviador.click(this.estiloAviador.bind(this));
		this.oval.click(this.estiloOval.bind(this));
		this.retangular.click(this.estiloRetangular.bind(this));
		this.gatiho.click(this.estiloGatinho.bind(this));
		this.quadrado.click(this.estiloQuadrado.bind(this));
		this.redondo.click(this.estiloRedondo.bind(this));
	}

	timerBf() {
		let finalDate = $(".date-countdown").text();
		let finalDate2 = $("#finalDate2 .date-countdown").text();
		$("#TimerBf, #TimerBf2").countdown(finalDate, function(event) {
			var $this = $(this);
			$this.html(
				event.strftime(`
			<div class="timerItem dia"><p> %D </p><span>dias</span></div>
			<div class="timerItem hora"><p> %H </p><span>hrs</span></div>
			<div class="timerItem min"><p> %M </p><span>min</span></div>
			<div class="timerItem seg"><p> %S </p><span>seg</span></div>`)
			);

			if (event.type == "finish") {
				$(".timer-bf .container.first").hide();
				$(".timer-bf .container.second").show();
			}
		});

		$("#TimerBf3").countdown(finalDate2, function(event) {
			var $this = $(this);
			$this.html(
				event.strftime(`
			<div class="timerItem dia"><p> %D </p><span>dias</span></div>
			<div class="timerItem hora"><p> %H </p><span>hrs</span></div>
			<div class="timerItem min"><p> %M </p><span>min</span></div>
			<div class="timerItem seg"><p> %S </p><span>seg</span></div>`)
			);

			if (event.type == "finish") {
				$(".timer-bf").hide();
			}
		});

		let data = new Date("2022/11/28");
		let hoje = new Date();

		if (hoje.getDate() == data.getDate()) {
			$(".timer-bf .container.first").hide();
			$(".timer-bf .container.second").hide();
			$(".timer-bf .container.third").show();
		}
	}

	linkBannerBf() {
		$("#home-content.bf .home-chamada .box-banner a").attr(
			"target",
			"_blank"
		);
	}

	configureDesktopBannersSlider() {
		setTimeout(() => {
			if (this.desktopBanners.hasClass("slick-initialized")) {
				this.desktopBanners.slick("unslick");
			}

			this.desktopBanners.slick({
				autoplay: true,
				autoplaySpeed: 4000,
				arrows: false,
				dots: true
			});
		}, 50);
	}

	configureMobileBannersSlider() {
		this.MobileBanners.slick({
			autoplay: false,
			autoplaySpeed: 4000,
			arrows: false,
			centerMode: true,
			dots: true
		});
	}
	configureprateleiraSlider() {
		this.prateleira.slick({
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 4,

			responsive: [
				{
					breakpoint: 1020,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 812,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						dots: false
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false
					}
				}
			]
		});
	}
	configureprateleiraEstiloSlider() {
		this.prateleiraEstilo.slick({
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1
		});
	}

	configureSlideEstilo() {
		this.slideEstilo.slick({
			arrows: false,
			dots: false,
			infinite: false,
			speed: 300,
			slidesToShow: 1,
			variableWidth: true
		});
	}

	estiloAviador() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#aviator")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-aviador").addClass("ver-estilo");
	}

	estiloOval() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#oval")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-oval").addClass("ver-estilo");
	}

	estiloRetangular() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#retangular")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-retangular").addClass("ver-estilo");
	}

	estiloGatinho() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#gatinho")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-gatinho").addClass("ver-estilo");
	}

	estiloQuadrado() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#quadrado")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-quadrado").addClass("ver-estilo");
	}

	estiloRedondo() {
		$(".estilos__estilos-opcao").removeClass("estilo-selecionado");
		$("#redondo")
			.parent()
			.addClass("estilo-selecionado");
		$(".estilo").removeClass("ver-estilo");
		$("#estilo-redondo").addClass("ver-estilo");
	}

	skeletonLoad() {
		setTimeout(function() {
			$(".advantage-bar.load").removeClass("load");
		}, 500);
	}

	postWord() {
		$.ajax({
			url: "https://www.blog.oticasdiniz.com.br/wp-json/wp/v2/posts",
			success: function(response) {
				console.log(response);
			}
		}).then(res => {
			const posts = res;
			let j = 0;
			for (let i = 0; i < posts.length; i++) {
				if (posts[i].media) {
					continue;
				}
				if (j == 3) {
					break;
				}
				const { link, title, yoast_head_json } = posts[i];
				const postNode = `
					<li>
						<img src="${yoast_head_json.og_image[0].url}" alt="" />
                        <a target="_blank" href="${link.rendered}">
                            ${title.rendered}
                        </a>


                        </li>
						`;
				$(".postsword ul").append(postNode);
				j++;
			}
		});
	}
	slickModelos() {
		$(".home-estilos ul").slick({
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 6,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						arrows: true,
						dots: true,
						infinite: true,
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
			]
		});
	}
}
