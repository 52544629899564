import { isSmallerThen768 } from "Helpers/MediasMatch";

export default class DiiCollectionHotsite {
	constructor() {
		this.selectors();
		this.events();
		this.addProductsName();
		this.addCategoriesName();
		this.buildShelfSlider();
	}

	selectors() {}

	events() {
		$(".youtube-button-icon").on("click", this.openYoutubeVideo.bind(this));
	}

	openYoutubeVideo(e) {
		let html = "";
		const url = $(e.target).attr("data-url");

		html += `<div class="popup-modal-wrapper">`;
		html += `<div class="popup-modal-container">`;
		html += `<div class="popup-modal-content">`;
		html += `<iframe`;
		html += ` width="100%"`;
		html += ` height="100%"`;
		html += ` src="https://www.youtube.com/embed/${url}"`;
		html += ` allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"`;
		html += `></iframe>`;
		html += `</div>`;
		html += `</div>`;
		html += `</div>`;

		$("main").prepend(html);

		$(".popup-modal-container").on("click", function() {
			$(".popup-modal-wrapper").remove();
		});
	}

	addProductsName() {
		const $products = $(".brand-shelf").find("li");

		$products.map((index, product) => {
			const name = $(product)
				.find(".shelf-url")
				.attr("title");

			const html = `<span>${name}</span>`;
			const brand = $(product).find("h3");

			$(html).insertAfter(brand);
		});
	}

	addCategoriesName() {
		const $categories = $(".selected-collections-list").find(".box-banner");

		$categories.map((index, category) => {
			const name = $(category)
				.find("img")
				.attr("alt");

			const html = `<span>${name}</span>`;

			$(category)
				.find("a")
				.append(html);
		});
	}

	buildShelfSlider() {
		$(".brand-shelf ul").slick({
			dots: false,
			arrows: true,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			speed: 500,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						lazyLoad: "ondemand",
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						arrows: false
					}
				}
			]
		});

		$(".selected-collections-list").slick({
			dots: false,
			arrows: true,
			infinite: true,
			slidesToShow: 7,
			slidesToScroll: 1,
			speed: 500,
			prevArrow:
				'<button type="button" class="slick-arrow slick-prev"></button>',
			nextArrow:
				'<button type="button" class="slick-arrow slick-next"></button>',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						lazyLoad: "ondemand",
						slidesToShow: 2,
						slidesToScroll: 1,
						dots: true,
						arrows: false
					}
				}
			]
		});
	}
}
