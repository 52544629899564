import ReceitaService from "App/components/Receita/ReceitaService";
import ReceitaView from "App/components/Receita/ReceitaView";
import ReceitaMedicaoPopup from "App/components/Receita/ReceitaMedicaoPopup";
import { receitaPassos } from "../functions/slide";

export default class Receita {
	constructor(ctx) {
		this.service = ctx.getService(ReceitaService.name);
		this.selectors();
		this.init();
	}

	selectors() {
		this.boxPassos = $(".receita__passos");
		this.containerReceita = document.querySelector("#receita");
	}

	async init() {
		receitaPassos(this.boxPassos);
        new ReceitaMedicaoPopup();
		const orderData = this.getOrderData();
		if (orderData) {
			// console.log(orderData);
			const orderId = orderData.orderID;
			const paramUserEmail = orderData.clientID;
			const skuIds = orderData.skusID;
			const userEmail = await this.service.getUserEmail();

			if (!orderId || !userEmail || !paramUserEmail || !skuIds.length) {
				return false;
			}

			if(userEmail != paramUserEmail){
				return false;
			}

			if (skuIds.length > 0) {
				this.service.store.commit("setOrderId", orderId);

				await this.service.store.commit("setClientId", userEmail);

				const hasReceitaItems = await this.service.verify(skuIds);
				if (hasReceitaItems) {
					this.view = new ReceitaView(
						this.service,
						this.containerReceita
					);
				}
			} else {
				console.log("Erro ao buscar o pedido. \n");
			}
		}
	}

	getOrderData() {
		const url = window.location.search;
		const urlParams = new URLSearchParams(url);
		const orderId = urlParams.get("id")
			? JSON.parse(window.atob(urlParams.get("id")))
			: false;
		return orderId;
	}
}
