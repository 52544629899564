import Overlay from "../components/Overlay";
import cookie from "../../helpers/cookie";

class HomePopup {
	constructor() {
		/* this.selectors();
		this.overlay = new Overlay(99998, "rgba(0, 0, 0, .5)");
		this.events();
		this.presentPopup(); */
	}

	selectors() {
		this.homePopup = $(".homePopup");
		this.closePopupButton = $(".homePopup__close");
	}

	events() {
		this.closePopupButton.click(this.closePopup.bind(this));
		this.overlay.registerClickEvent(this.closePopup.bind(this));
	}

	presentPopup() {
		if (!(cookie.get("home_popup") === "true")) {
			setTimeout(() => {
				this.homePopup.fadeIn(200);
				this.overlay.show();
			}, 1000);
		}
	}

	closePopup(event) {
		event.preventDefault();
		this.homePopup.fadeOut(200);
		this.overlay.hide();
		cookie.set("home_popup", true, 60 * 60 * 24 * 7);
	}
}

export default HomePopup;
