import slickConfig from "Config/slick";

export default class DiiSlider {
	constructor() {
		this.selectors();
		this.createModalSlider();
		this.btnToOpenModal();
		this.slickModelos();
	}

	selectors() {

	}

	btnToOpenModal() {
		const btnOpen = $(".dii-modelos__list .box-banner");

		btnOpen.map((e) => {
			let singleBtnOpen = btnOpen[e];

			function setAttributes(el, options) {
				Object.keys(options).forEach(function(attr) {
					el.setAttribute(attr, options[attr]);
				})
			}
			setAttributes(singleBtnOpen, {"data-id": e, "data-toggle": "modal", "data-target": "#myModal"});
		})

		btnOpen.on("click", function() {
			const itemId = $(this).data("id")
			const modalSlider = $(".dii-modelos__modal-item .dii-modelos__list");
			$(".dii-modelos__modal .modal.fade").addClass("show")
			modalSlider.slick("slickGoTo", itemId)
		})

		$("#myModal .modal-dialog .modal-body .btn-secondary").on("click", function(){
			$(".dii-modelos__modal .modal.fade").removeClass("show")
		})
	}

	createModalSlider() {
		$(".dii-modelos__list").clone().appendTo(".dii-modelos__modal-item");
	}

	slickModelos() {
		const modalSlider = $(".dii-modelos__modal-item .dii-modelos__list");
		modalSlider.slick(slickConfig.modeloSlide.bannerModalModelos);
		
		const descModal = $(".dii-modelos__descricao");
		descModal.slick(slickConfig.modeloDescSlide.DescModalModelos);
		console.log(modalSlider, descModal);
	}
}
