import MasterData from "Helpers/MasterData";

export default class SejaFranqueado{
  constructor(){
    this.franqueadoMD = new MasterData("SF");

    this.events();
  }

  events(){
    $('.seeMoreReasons').on('click',(e)=>{
      $(e.target).addClass('hidden');
      $('.seeLessReasons').removeClass('hidden');
      $('.franqueado__razoes__grid').removeClass('closed');
      $('.franqueado__razoes__grid').addClass('opened');
    })

    $('.seeLessReasons').on('click',(e)=>{
      $(e.target).addClass('hidden');
      $('.seeMoreReasons').removeClass('hidden');
      $('.franqueado__razoes__grid').removeClass('opened');
      $('.franqueado__razoes__grid').addClass('closed');
    })

    $('#SejaFranqueadoForm').on('submit',(e)=>{
      e.preventDefault();
      $('#SejaFranqueadoForm .enviar').addClass('disable');

      var data = {
        nome: $("#SejaFranqueadoForm #nome").val(),
        email: $("#SejaFranqueadoForm #email").val(),
        celular_ddd: $("#SejaFranqueadoForm #celular_ddd").val() + $("#SejaFranqueadoForm #celular").val(),
        estado_pretendido: $(
          "#SejaFranqueadoForm select#estado_pretendido option:selected"
        ).val(),
        cidade_pretendida: $(
          "#SejaFranqueadoForm #cidade_pretendida"
        ).val(),
        atua_ramo: $(
          "#SejaFranqueadoForm select#atua_ramo option:selected"
        ).val(),
        como_chegou: $("#SejaFranqueadoForm select#como_chegou option:selected").val(),
        capital: $("#SejaFranqueadoForm select#capital option:selected").val(),
        comentario: $("#SejaFranqueadoForm #comentario").val(),
      };

      if(this.validate(data)){
        var bodyData = JSON.stringify(data);
  
        this.handleFormData('RF', bodyData, 
        function (success) {
            // swal(
            //   "Obrigado pela mensagem!",
            //   "Recebemos sua mensagem com sucesso, aguarde nosso contato",
            //   "success"
            // );
            location.href="/seja-um-franqueado/obrigado";
            $(
              "#SejaFranqueadoForm input, #SejaFranqueadoForm textarea"
            ).val(""),
            $("#SejaFranqueadoForm .enviar").removeClass("disable");
        })
      }else{
        setTimeout(()=>{
          $("#SejaFranqueadoForm .enviar").removeClass("disable");
        }, 700)
      }


    })
  }

  validate(data){
    let bool = true;
    const resps = $("#SejaFranqueadoForm select[required=required]");
    resps.each((index, element)=>{
      if($(element).find('option:selected').val() == -1){
        const elementId = $(element).attr('id');
        $(`#${elementId}`).trigger('focus');
        alert("Preencha todos os campos obrigatórios")
        bool =  false;
        return false;
      }
    })

    return bool;
  }

  handleFormData(entity, data, callBack ){
    $.ajax({
      url: "/api/dataentities/" + entity + "/documents/",
      type: "POST",
      data: data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      success: function (success) {
        callBack(success);
      },
      error: function (error) {
        swal("Oops", "Houve um erro tente mais tarde!", error);
        $("#SejaFranqueadoForm .enviar").removeClass("disable");
      },
    });
  }
}