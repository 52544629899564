export default class Institucional {
	constructor() {
		this.selectors();
		this.events();
	}

	selectors() {
	}
	events() {
		if ($(window).width() <= 768) this.configurefranqueadosSlider();
	}
	configurefranqueadosSlider() {
		this.franqueados.slick({
			autoplay: true,
			autoplaySpeed: 4000,
			arrows: false,
			dots: true,
			variableWidth: true
		});
	}
}
