export default class DiiCollection{
  constructor(){
    this.selectors();
    this.events();
  }

  selectors(){
    this.sectionSobreNos = $("#sobre-nos");
    this.sectionModelos = $("#modelos");
    this.sectionAcesse = $("#acesse");
    this.header = $(".main-header");
  }

  events(){
    document.body.addEventListener("scroll", (event) => {
      const sobrenosTop = this.sectionSobreNos.offset().top;
      const modelosTop = this.sectionModelos.offset().top;
      const acesseTop = this.sectionAcesse.offset().top;

      if (sobrenosTop < 1){
        this.header.addClass("sobrenos");
      }else if(sobrenosTop > 1){
        this.header.removeClass("sobrenos");
      } 
      if(modelosTop < 100){
        this.header.removeClass("sobrenos");
        this.header.addClass("modelos");
      }else if(modelosTop > 100){
        this.header.removeClass("modelos");
      }

      if(acesseTop < 100){
        this.header.removeClass("modelos");
        this.header.addClass("hide");
      }else if(acesseTop > 0){
        this.header.removeClass("hide");
      }
    });
  }
}