import Container from "../core/Container";

import Home from "./pages/Home";
import CustomLens from "./pages/CustomLens";
import Receita from "./pages/Receita";
import Produto from "./pages/Produto";
import Geral from "./pages/Geral";
import { LenteContato, LentesContatoPage } from "./pages/LentesContato";
import Categoria from "./pages/Categoria";
import Institucional from "./pages/Institucional";
import DiiCollection from "./pages/DiiCollection";
import DeOlho from "./pages/DeOlho";
import SejaFranqueado from "./pages/SejaFranqueado";
import Campanha from "./pages/Campanha";
import DiiCollectionHotsite from "./pages/hotsites/DiiCollectionHotsite";

import HomePopup from "./partials/HomePopup";
import Brands from "./partials/Brands";
import AdvantageBar from "./partials/AdvantageBar";
import Accessories from "./partials/Accessories";
import Login from "./partials/Login";
import Footer from "./partials/Footer";
import Menu from "./partials/Menu";

import ReceitaService from "../app/components/Receita/ReceitaService";
import Minicart from "./components/Minicart";
import DiiSlider from "./components/DiiSlider";
import ScrollToTop from "./components/ScrollToTop";
import Newsletter from "./components/Newsletter";

const geral = new Geral();

const app = new Container({
	appName: "oticasdiniz",
	components: [
		DiiSlider,
		Brands,
		AdvantageBar,
		Minicart,
		Login,
		Menu,
		Footer,
		ScrollToTop,
		Newsletter
	],
	services: [ReceitaService],
	pages: [
		{
			bodyClass: "home",
			components: [Home, HomePopup]
		},
		{
			bodyClass: "product",
			components: [Produto, Accessories]
		},
		{
			bodyClass: "lente-customizada",
			components: [CustomLens]
		},
		{
			bodyClass: "lente-contato",
			components: [LentesContatoPage]
		},
		{
			bodyClass: "receita",
			components: [Receita]
		},
		{
			bodyClass: "search",
			components: [Categoria]
		},
		{
			bodyClass: "institucional",
			components: [Institucional]
		},
		{
			bodyClass: "diicollection",
			components: [DiiCollection]
		},
		{
			bodyClass: "de-olho",
			components: [CustomLens]
		},
		{
			bodyClass: "franqueado",
			components: [SejaFranqueado]
		},
		{
			bodyClass: "regulamento",
			components: [Campanha]
		},
		{
			bodyClass: "hotsite dii",
			components: [DiiCollectionHotsite]
		}
	]
});

app.bind(Minicart.name, ".minicartBox");
app.bind(Newsletter.name, {
	elemento: ".news-form",
	textButtom: "Enviar"
});
export default app;
