export default class Brands {
	constructor() {
		this.selectors();
		setTimeout(this.getBrands.bind(this), 500);
	}

	selectors() {
		this.menuBrands = $(".main-nav-marcas .box_marcas");
		this.brandsList = $(".home-brands ul#desk ");
		this.brandsListMob = $(".home-brands list_brand");
	}

	getBrands() {
		$.ajax({
			url:
				"/api/dataentities/MC/search?_fields=id_marca,coluna,marca,id&_where=menu=true&_sort=marca asc",

			type: "GET",

			headers: {
				Accept: "application/json; charset=utf-8",
				"Content-Type": "application/json; charset=utf-8",
				"rest-range": "resources=0-49"
			}
		}).done(brands => {
			this.renderMenuBrands(brands);
			this.renderPageBrands(brands);
			this.renderSliderBrands(brands);
			this.menuBrands.find(".dix").addClass("visible");
			this.brandsList.addClass("visible");
		});
	}


	renderPageBrands(data) {
		this.menuBrands.find("[class*=column_] ul").empty();
		for (var i = 0; i < data.length; i++) {
			data[i].coluna == data[i].coluna &&
				this.menuBrands.find(`.column_${data[i].coluna} ul`).append(
					`<li>
						<h3> <a href="/${data[i].marca.replace(" ", "-")}">${data[i].marca}</a> </h3>
					</li>`
				);
		}
	}
	renderMenuBrands(data) {
		data.map((e) => {
			$(".diniz-brands .page-brands .container-page-brands .list-page-brands").append(`<li class="page-brands-list ${e.marca}"><a href="/${e.marca.replace(" ", "-")}"></a></li>`)
		})
	}
	renderSliderBrands(data) {
		this.brandsList.slick("unslick");
		this.brandsList.empty();

		for (var i = 0; i < data.length; i++) {
			"Polaroid Kids" !== data[i].marca &&
				this.brandsList.append(
					`<li class="brand-list-list-desk" >
						<a class="${data[i].marca}" href="/${data[i].marca.replace(" ", "-")}">
							
						</a>
					</li>
					`
				);
				this.brandsListMob.append(
					`<li class="brand-list-list-mob" >
						<a class="${data[i].marca}" href="/${data[i].marca.replace(" ", "-")}">
						
						</a>
					</li>
					`
				);
		}

		this.createBrandsSlider();
	}

	createBrandsSlider() {
	this.brandsList.slick({
			dots: true,
			infinite: true,
			slidesPerRow: 5,
			rows: 2,
			arrows: true
		});
		this.brandsListMob.slick({
			arrows: true,
			dots: true,
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1
		});
	}
}
