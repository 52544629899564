import {
	M3Dropdown
} from "App/partials/m3-dropdown";

export class LentesContatoView {

	constructor(options = {}) {
		this.options = {
			graus: [],
			maxQtdPorInput: 24,
			qtd: 24,
			onchangedEvent: 'prescricao-changed',
		};
		this.options = Object.assign(this.options, options);
	}

	init() {
		this._render();
		this._selectors();
		this._events();
		this.update();
		return this;
	}


	onChange(cb) {
		$(document).on(this.options.onchangedEvent, cb);
	}


	update() {
		const lentes = this.getSelectedValues();
		this._lenteUnica(this.isLenteUnica());
		this._updateQuantidade(lentes);
		this._duracao(lentes);
		
		this.$element.trigger(this.options.onchangedEvent, lentes);
		this._aviseMeSkuUpdate(lentes);
	}


	//Retorona as lentes selecionadas
	getSelectedValues() {
		let selectedValues = {};
		//Se 'Somente um Grau' estiver marcado
		if (this.isLenteUnica()) {
			selectedValues = {
				lente_direita: {
					grau: this.$lenteEsquerdaDireitaInput.find('.selected_value').text(),
					skuId: this.$lenteEsquerdaDireitaInput.val(),
					qtd: Number(this.$lenteEsquerdaDireitaQtdInput.val())
				}
			}
		} else {
			selectedValues = {
				lente_direita: {
					grau: this.$lenteDireitaInput.find('.selected_value').text(),
					skuId: this.$lenteDireitaInput.val(),
					qtd: Number(this.$lenteDireitaQtdInput.val())
				},
				lente_esquerda: {
					grau: this.$lenteEsquerdaInput.find('.selected_value').text(),
					skuId: this.$lenteEsquerdaInput.val(),
					qtd: Number(this.$lenteEsquerdaQtdInput.val())
				}
			}
		}

		return selectedValues;
	}

	feedbackMessage(message, type = 'succes') {
		const el = $(`<span class="${type}">${message}</span>`);
		this.$message.html(el);
		setTimeout(() => el.fadeOut(), 2500);
	}

	isLenteUnica() {
		return this.$somenteGrauInput[0].checked;
	}
	//**PRIVATE METHODS **/

	_selectors() {
		this.$element = $('.prescricao');
		this.$lentes = $('.prescricao__lentes');
		this.$message = $('.message-wrapper');
		this.$duracao = $('.prescricao__duracao');

		this.$somenteGrauInput = $('.input_somenteGrau');
		this.$lenteDireitaInput = $('.lente-direita .input_lentes');
		this.$lenteDireitaQtdInput = $('.lente-direita .input_quantidade');
		this.$lenteEsquerdaInput = $('.lente-esquerda .input_lentes');
		this.$lenteEsquerdaQtdInput = $('.lente-esquerda .input_quantidade');
		this.$lenteEsquerdaDireitaInput = $('.lente-diretia-e-esquerda .input_lentes');
		this.$lenteEsquerdaDireitaQtdInput = $('.lente-diretia-e-esquerda .input_quantidade');

		this.$aviseMeContainer = $(".avise-me-container")
		this.$aviseMeForm = $("#enviar-avise-me");
		this.$SubscribeFor = $(".skus-to-subscribe");
	}

	_events() {
		this.$somenteGrauInput.on('change', this.update.bind(this))
		this.$lenteDireitaInput.on('change', this.update.bind(this))
		this.$lenteDireitaQtdInput.on('change', this.update.bind(this))
		this.$lenteEsquerdaInput.on('change', this.update.bind(this))
		this.$lenteEsquerdaQtdInput.on('change', this.update.bind(this))
		this.$lenteEsquerdaDireitaInput.on('change', this.update.bind(this));
		this.$lenteEsquerdaDireitaQtdInput.on('change', this.update.bind(this));
		this.$aviseMeForm.on('click', this.sendAviseMeForm.bind(this));
	}

	_render() {
		let aviseMe = `<div class="avise-me-container" style="display:none;">
		<fieldset class="form-avise-me">
			<h2> Produto Indisponível </h2>
			<div class="skus-to-subscribe">
				<div class="lente-falta" style="display:none;">
					A(s) lente(s) : <span class="direita"></span>
					<span class="esquerda"></span>
				</div>
			</div>
			<div class="avise-me-wrapper">
				<label >
					<span>Nome</span>
					<input name="notifymeClientName" type="text" id="nome-avise-me"
					placeholder="nome">
				</label>
				<label >
					<span>E-mail</span>
					<input name="notifymeClientEmail" type="text" id="email-avise-me"
					placeholder="e-mail">
				</label>
			</div>
			<input name="notifymeIdSku" type="hidden" id="sku-avise-me" class="notifyme-skuid" value="0">
			<input name="notifymeButtonOK" type="button" id="enviar-avise-me" class="btn-enviar" value="Enviar">
			<p class="status"></p>
		</fieldset>
	</div>`;
	
		const html = $(`
			<section class="prescricao">
				<div class="prescricao__content">
					<div class="prescricao__title">
						<span>Prescrição</span> <label><input class="input_somenteGrau" type="checkbox">Somente um grau</input></label>
					</div>

					<div class="prescricao__lentes">
						<div class="selecao-lente lente-direita">
							<span>Olho Direito</span>
							<div class="graus-wrapper"></div>
							<span>Quantidade</span>
							<span class="quantidade-wrapper"></span>
						</div>
						<div class="selecao-lente lente-esquerda">
							<span>Olho Esquerdo</span>
							<div class="graus-wrapper"></div>
							<span>Quantidade</span>
							<span class="quantidade-wrapper"></span>
						</div>

						<div class="selecao-lente lente-diretia-e-esquerda">
							<span>Apenas um grau</span>
							<div class="graus-wrapper"></div>
							<span>Quantidade</span>
							<span class="quantidade-wrapper"></span>
						</div>
					</div>

					<div class="prescricao__duracao"></div>
				</div>
			</section>
			${aviseMe}
		`);

		const message = `<div class="message-wrapper"></div>`;

		let target = $('.lestes-contato-module');
		target.append(html);

		target = target.find('.prescricao__content');
		target.append($('.product-buy'));
		target.append(message);

		this._renderDropdowns();
	}

	_renderDropdowns() {
		// Renderiza os Dropdowns de graus (lente-esquerda, lente-direita e lente-unica)
		const graus = this.options.graus;
		const grausInputWrapper = $('.selecao-lente .graus-wrapper');
		grausInputWrapper.each((i, el) => {
			const $el = $(el);
			$el.append(this._generateDropdownGraus(graus));
		});

		// Renderiza os Dropdowns de quantidada (lente-esquerda, lente-direita e lente-unica)
		const quantidadeInputWrapper = $('.selecao-lente .quantidade-wrapper');
		quantidadeInputWrapper.each((i, el) => {
			const $el = $(el);
			$el.append(this._generateDropdownQuantidade());
		});
	}

	_generateDropdownGraus(graus) {
		const options = {
			placeholder: 'Nenhum',
			class: 'input_lentes',
			values: graus
		}
		return new M3Dropdown(options);
	}

	_generateDropdownQuantidade() {
		const max = this.options.maxQtdPorInput;
		const qtd = this.options.qtd;

		let inputValues = [];
		for (let i = 1; i <= max && i <= qtd; i++) {
			inputValues.push({
				value: i,
				display: i
			});
		}

		const options = {
			placeholder: 'Nenhum',
			class: 'input_quantidade',
			selectFirstAsDefault: true,
			values: inputValues
		}

		return new M3Dropdown(options);
	}

	_lenteUnica(isLenteUnica) {
		if (isLenteUnica) {
			this.$lentes.addClass('apenas-um-grau');
		} else {
			this.$lentes.removeClass('apenas-um-grau');
		}
	}

	//Aviso de duracao
	_duracao(lentes) {
		const build = (posicao, qtd) => {
			const meses = qtd * 3;
			return `<span class="${posicao? 'has-posicao' : ''}"><i class="sprite sprite-warning warning-duracao"></i>A quantidade selecionada ${posicao ? `para o <strong> olho ${posicao} </strong> ` : ''}tem duração de até ${meses} meses</span>`;
		}

		const qtdDireita = lentes.lente_direita.qtd;
		const qtdEsquerda = lentes.lente_esquerda ? lentes.lente_esquerda.qtd : null;

		let html = '';
		if (qtdEsquerda && qtdDireita != qtdEsquerda) {
			html = `
				${build('direito', qtdDireita)}
				<br>
				${build('esquerdo', qtdEsquerda)}
			`;
		} else {
			html = `${build('', qtdDireita)}`;
		}

		this.$duracao.html(html);
	}


	//Sincroniza a quantidade nos inputs para impedir que o usuario escolha uma quantidade maior que o disponivel
	_updateQuantidade(lentes) {
		if (this.isLenteUnica()) return;
		const totalSelecionadoDireita = Number(lentes.lente_direita.qtd);
		const totalSelecionadoEsquerda = Number(lentes.lente_esquerda.qtd);
		const maximoGeral = this.options.qtd;

		//verifica se a quantidade pode ser adicionado ao carrinho sem que ela estoure o maximo disponivel
		const checkBuy = (desired_qtd, max_qtd) => {
			return desired_qtd <= max_qtd;
		}

		//limita quantas 'quantidades' o dropdown pode exibir
		const changeMax = (dropdown, maximo) => {
			dropdown.find('m3-dropdown-item').each((i, _el) => {
				const option = $(_el);
				const optionValue = Number(option.attr('value'));

				if (checkBuy(optionValue, maximo)) {
					//Se for possivel comprar, exibe a opcao
					option.show();
				} else {
					option.hide();
				}
			});
		}

		changeMax(this.$lenteDireitaQtdInput, maximoGeral - totalSelecionadoEsquerda);
		changeMax(this.$lenteEsquerdaQtdInput, maximoGeral - totalSelecionadoDireita);
	}

	async sendAviseMeForm(){
		var aviseme = $(".form-avise-me");
		const lentes = this.getSelectedValues();

		if(lentes.lente_direita && lentes.lente_direita.grau != "Nenhum" && !this.isSkuAvailable(lentes.lente_direita.skuId)){
			$(aviseme).find("#sku-avise-me").val(lentes.lente_direita.skuId);

			await this.aviseMeRequest(aviseme);
		}
		
		if(!this.isLenteUnica() && lentes.lente_esquerda && lentes.lente_esquerda.grau != "Nenhum"){
			if(!this.isSkuAvailable(lentes.lente_esquerda.skuId)){
				$(aviseme).find("#sku-avise-me").val(lentes.lente_esquerda.skuId);

				await this.aviseMeRequest(aviseme);
			}
		}
	}

	aviseMeRequest(aviseme){
		if (!this.verifyAviseMe(aviseme)) {
			return false;
		}

		$.ajax({
			type: "POST",
			url: "/no-cache/AviseMe.aspx",
			data: aviseme.serialize(),
			success: function () {
				aviseme
					.find("p.status")
					.html("Cadastrado com sucesso!")
					.addClass("msgSucesso");
				aviseme.find("input").hide();
				aviseme.find("h3").hide();
			}.bind(this),
			error: function () {
				aviseme
					.find("p.status")
					.html("Ocorreu algum erro. Tente novamente mais tarde.")
					.addClass("msgErro");
			}.bind(this),
		});
	}

	verifyAviseMe(aviseme) {
		var nome = aviseme.find("#nome-avise-me");
		var email = aviseme.find("#email-avise-me");
		var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
		if( nome.val() == '' ) {
			alert( 'Por favor, digite seu nome' );
			nome.focus();
			return false;
		}
		if (!filter.test(email.val())) {
			alert("Por favor, digite o email corretamente");
			email.focus();
			return false;
		}
		return true;
	}


	isSkuAvailable (skuId){
		const findSku = this.options.graus.find((grau)=> grau.value == skuId)

		if(findSku){
			const available = findSku.availablequantity
			if(available < 1){
				return false;
			}

			return true;
		}

		return false;
	}
	
	_aviseMeSkuUpdate(lentes){
		if(lentes.lente_direita && lentes.lente_direita.grau != "Nenhum"){

			if(!this.isSkuAvailable(lentes.lente_direita.skuId)){
				this.$SubscribeFor.find(".lente-falta .direita").text(lentes.lente_direita.grau);
				this.$SubscribeFor.find(".lente-falta").css("display", "block");
				this.$aviseMeContainer.css("display", "block");
			}else{
				this.$SubscribeFor.find(".lente-falta .direita").text("");
			}
		}

		if(lentes.lente_esquerda && lentes.lente_esquerda.grau != "Nenhum"){
			if(!this.isSkuAvailable(lentes.lente_esquerda.skuId)){
				this.$SubscribeFor.find(".lente-falta .esquerda").text(lentes.lente_esquerda.grau);
				this.$SubscribeFor.find(".lente-falta").css("display", "block");
				this.$aviseMeContainer.css("display", "block");
			}else{
				this.$SubscribeFor.find(".lente-falta .esquerda").text("");
			}
		}

		if(this.$SubscribeFor.find(".lente-falta .esquerda").text() == "" && this.$SubscribeFor.find(".lente-falta .direita").text() == ""){
			this.$aviseMeContainer.css("display", "none");
		}else if(this.isLenteUnica() && this.$SubscribeFor.find(".lente-falta .direita").text() == ""){
			this.$aviseMeContainer.css("display", "none");
		}
	}

}
