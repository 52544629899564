export async function GetImgHover() {
	$.each($(`.shelf-image:not(".addImg")`), async (index, item) => {
		if (item.id) {
			let data = await (
				await fetch(
					`/api/catalog_system/pub/products/search/?fq=productId:${item.id}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/vnd.vtex.ds.v10+json",
							"REST-Range": "resources=0-1000"
						}
					}
				).catch(e => console.log(e))
			).json();
			if (data[0].items) {
				if (data[0].items[0].images.length >= 2) {
					let urlImg = data[0].items[0].images[1].imageUrl;
					let html = `<div class="content-hover">
					<img src="${urlImg}" class="img-hover"/>
				</div>`;

					if (`$(${item}:not(".addImg")`) {
						$(item).append(html);
						$(item).addClass("addImg");
					}
				}
			}
		}
	});
}
