import Store from "Helpers/State/Store";

export const ImageStore = function(storeOpts = {}) {
	return new Store({
		moduleName: storeOpts.moduleName || "ImageComponens",
		state: {
			listaImagens: [],
			destaques: "",
			videos: [],
			...storeOpts.state
		},
		mutations: {
			setListaImagens(state, listaImagens) {
				state.listaImagens = [...listaImagens];
			},
			setDestaque(state, destaque) {
				state.destaque = destaque;
			},
			...storeOpts.mutations
		},
		actions: { ...storeOpts.actions }
	});
};
