import OverLay from "../components/Overlay";

export default class Menu {
	constructor() {
		this.selectors();
		this.overlay = new OverLay(10, "rgba(0,0,0, 0.5)");
		this.events();
		this.MenuAcessibilidade();
	}

	selectors() {
		this.btnMenuMobile = $(".open-nav img, .footer-menu");
		this.btncloseMenu = $(".close-menu-mobile");
		// this.btnLVL1 = $(".sub-menu-lvl1");
		// this.btnCloseSubMenuMobile = $(".back-sub-menu");
		// this.btnLVL3 = $(".sub-menu-lvl2");
		this.btnSearch = $(
			".header__main__search-user-cart .search a, .header__main__search-user-cart .closeSearchBox"
		);
		this.btnUser = $(".user > a, .overlay-login");
	}

	events() {
		this.btnMenuMobile.on("click", this.toggleSubmenu.bind(this));
		this.btncloseMenu.on("click", this.closeMenu.bind(this));
		// this.btnLVL1.on("click", this.toggleLVL1.bind(this));
		// this.btnCloseSubMenuMobile.on("click", this.toggleClose.bind(this));
		// this.btnLVL3.on("click", this.toggleLVL3.bind(this));
		this.btnSearch.on("click", this.toggleSearch.bind(this));
		this.btnUser.on("click", this.toggleUser.bind(this));
		this.overlay.registerClickEvent(this.closeMenu.bind(this));
		this.overlay.registerClickEvent(this.closeUser.bind(this));
	}

	toggleSubmenu() {
		$(".header__main__sub-mobile").toggleClass("toggle");
		this.overlay.show();
	}

	closeMenu() {
		this.overlay.hide();
		$(".header__main__sub-mobile").removeClass("toggle");
	}

	// toggleLVL1(e) {
	// 	$(e.target)
	// 		.parent(".sub-menu-lvl1")
	// 		.find(".sub-menu-lvl2")
	// 		.toggleClass("toggle");
	// }

	toggleLVL3(e) {
		// $(e.target)
		// 	.parent("li")
		// 	.find(".sub-menu-lvl3-content")
		// 	.slideToggle("toggle");

		$(e.target)
			.parent("li")
			.find(".name-arrow")
			.toggleClass("toggle");
	}

	// toggleClose(e) {
	// 	$(e.currentTarget)
	// 		.parent()
	// 		.toggleClass("toggle");
	// }

	toggleSearch() {
		const screen = $(window).width();

		if (screen >= "1100") {
			$(".searchBox").animate({ width: "toggle" });
			$(
				".header__main__menu .categoria, .header__main__search-user-cart .search a"
			).toggleClass("toggle");
		} else {
			$(".searchBox-Mobile").slideToggle("toggle");
		}
	}

	toggleUser() {
		$(".user .userBox, .overlay-login").toggleClass("toggle");
		/* this.overlay.show(); */
	}
	closeUser() {
		/* 	this.overlay.hide(); */
		$(".user .userBox, .overlay-login").removeClass("toggle");
	}

	MenuAcessibilidade() {
		$("body").on("scroll", function() {
			if ($("body").scrollTop() > 100) {
				$(".logoAcess").addClass("ativo");
				$(".logoDesk").addClass("ativo");
				$(".header .categoria").addClass("desativo");
			} else {
				$(".logoAcess").removeClass("ativo");
				$(".logoDesk").removeClass("ativo");
				$(".header .categoria").removeClass("desativo");
				$(".header .categoria").removeClass("ativo");
			}
		});

		$("body").on("click", ".header__main__logo .logoAcess.ativo", function(
			e
		) {
			e.stopPropagation();
			$(".header .categoria").toggleClass("ativo");
		});
	}
}
