import { isSmallerThen768 } from "Helpers/MediasMatch";

// @TODO: Ratorar essa classe, dividar em classes menores para cada funcionalidade
export default class Geral {
	constructor() {
		$(window).on("load", () => {
			this.ProdutoOutOf();
		});
		this.acessibilidade();
		this.loginHeader();
		if (isSmallerThen768) {
			this.bottomOptions();
		}
	}

	bottomOptions() {
		var offset = 200;
		$("body").scroll(function() {
			if ($(this).scrollTop() > offset) {
				$(".mobile-bottom-options").addClass("active");
			} else {
				$(".mobile-bottom-options").removeClass("active");
			}
		});

		$(".mobile-bottom-options .show-search button").on("click", function() {
			$("html, body").animate(
				{
					scrollTop: 0
				},
				500
			);

			$(".busca-mobile .fulltext-search-box").focus();
		});
	}
	ProdutoOutOf() {
		const shelf = $(".shelf .prateleira ul li");

		this.Cleanestoque(shelf);

		$("body.search .btn-load-more").on("click", e => {
			e.preventDefault();
			setTimeout(() => {
				const shelf = $(".shelf .prateleira ul li");
				this.Cleanestoque(shelf);
			}, 1000);
		});
	}
	Cleanestoque(shelf) {
		$(shelf).each((index, element) => {
			const shelfOutOf = $(element).find(".outOfStock");
			if (shelfOutOf.length == 0) {
				$(".btn-load-more").css("display", "block");
			} else {
				$(element).addClass("desativo");
				setTimeout(() => {
					$(".btn-load-more").css("display", "none");
				}, 1000);
			}
		});
	}
	acessibilidade() {
		const selectElement = document.getElementById("controller__select");

		selectElement.onchange = function() {
			[...document.body.classList].forEach((className, i) => {
				if (className.startsWith("zoom")) {
					document.body.classList.remove(className);
				}
			});
			document.body.classList.toggle(this.value);
		};

		const selectElementMobile = document.getElementById(
			"controller__select-mobile"
		);

		selectElementMobile.onchange = function() {
			[...document.body.classList].forEach((className, i) => {
				if (className.startsWith("zoom")) {
					document.body.classList.remove(className);
				}
			});
			document.body.classList.toggle(this.value);
		};

		$(".item__controller input").on("click", function() {
			$("body").toggleClass("filter");
		});

		$(".header__topbar__topbar01 ul").slick({
			arrows: false,
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000
		});

		$(".sub-menu-lvl1").on("click", function() {
			$(this)
				.find(".sub-menu-lvl2")
				.addClass("ativo");
		});
		$(".sub-menu-lvl1 .sub-menu-lvl2-content ul .back-sub-menu").on(
			"click",
			function(e) {
				e.stopPropagation();
				$(".sub-menu-lvl2").removeClass("ativo");
			}
		);
	}

	loginHeader() {
		vtexjs.checkout.getOrderForm().done(function(orderForm) {
			if (orderForm.loggedIn) {
				const confLogin = vtexjs.checkout.orderForm.loggedIn;
				const nameLogin =
					vtexjs.checkout.orderForm.clientProfileData.email;
				$(".close-menu-mobile").before(
					"<a href='/account' class='loginFeito'>Olá " +
						nameLogin +
						"</a><span>...</span>"
				);
			} else {
				$(".close-menu-mobile").before(
					"<a href='/account'  class='loginFail'>Faça o <strong>login</strong> ou <strong>cadastre-se!</strong></a>"
				);
			}
		});
	}
}
