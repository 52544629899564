export default {
	modeloSlide: {
		bannerModalModelos: {
			dots: false,
			arrows: true,
			pauseOnHover: false,
			autoplay: false,
			infinite: false,
			autoplaySpeed: 6000,
			asNavFor: '.dii-modelos__descricao'
		}
	},

	modeloDescSlide: {
		DescModalModelos: {
			dots: false,
			arrows: false,
			pauseOnHover: false,
			autoplay: false,
			infinite: false,
			autoplaySpeed: 6000,
			asNavFor: '.dii-modelos__modal-item .dii-modelos__list'
		}
	}
};
