/*
 * 'slideResponsivo': Adiciona o passador (slider) na prateleira (shelf)
 *
 * @help http://kenwheeler.github.io/slick/
 */
export function receitaPassos(elemento) {
	let $elemento =
		typeof elemento !== "undefined" ? $(elemento) : $(".receita_passos");

	$elemento.slick({
		dots: false,
		arrows: false,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		speed: 500,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					lazyLoad: "ondemand",
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true
				}
			},
			{
				breakpoint: 768,
				settings: {
					dots: false,
					lazyLoad: "ondemand",
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					variableWidth: true
				}
			},
			{
				breakpoint: 576,
				settings: {
					dots: false,
					lazyLoad: "ondemand",
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					variableWidth: true
				}
			}
		]
	});
}
