import MasterData from "Helpers/MasterData";

export default class CustomLens {
	constructor() {
		this.specifications = [
			{
				id: 135,
				descriptionMD: "FD",
				// Magma \/
				// id: 131,
				name: "Foco"
			},
			{
				id: 136,
				descriptionMD: "LD",
				// Magma \/
				// id: 132,
				name: "Tipo de Lente"
			},
			{
				id: 137,
				descriptionMD: "MD",
				// Magma \/
				// id: 133,
				name: "Material"
			} /* ,
			{
				id: 127,
				descriptionMD: "AT",
				// Magma \/
				// id: 134,
				name: "AntiReflexo"
			} */
		];

		this.tamanhoLentesId = 139;
		this.actualStep = -1;
		this.previousStep = -1;

		try {
			window.window.vtexjs.checkout.getOrderForm();
			(async () => {
				this.GetProductTamanhoLente();
			})();
		} catch (error) {
			console.warn("vtexjs not found.");
		}

		if (
			dataLayer[0].productDepartmentId == 4 ||
			$("body").hasClass("lente-customizada")
		) {
			this.focoMD = new MasterData("FD");
			this.tipoLenteMD = new MasterData("LD");
			this.materialMD = new MasterData("MD");
			this.prescriptionMD = new MasterData("PC");
			/* this.antireflexoMD = new MasterData("AT"); */
			this.selectors();

			this.createPopUp(this.element);

			this.events();
			this.getBrandName();
		} else {
			$(
				".product-main .product-buy .buy-button, .product-main .product-extra-info"
			).addClass("not-customizable");
		}
	}

	selectors() {
		this.element = $(".product-main-content	");
	}

	events() {
		const _this = this;

		$(".product-main .buy-button").click(e => {
			e.preventDefault();
			$(".custom-lens").addClass("active");
		});

		$(".custom-lens__header__close").click(() => {
			$(".custom-lens").removeClass("active");
		});

		$(".showWarning").on("click", () => {
			$(".custom-lens__warning").addClass("active");
		});

		$(".custom-lens__choice__next").click(() => {
			const lensChoice = $(
				'.custom-lens input[id*="choice"]:checked'
			).val();

			if (lensChoice == "com-grau") {
				$(".custom-lens__steps").addClass("active");
				_this.setStep(0);
			} else {
				_this.getChosenSkus(false);
			}
		});

		$("body").on("change", '.custom-lens input[id*="choice"]', function() {
			$("button." + $(this).attr("name")).prop("disabled", false);
		});

		$("body").on("change", '.custom-lens input[id*="step"]', function() {
			$("button." + $(this).attr("name")).prop("disabled", false);
			$("button." + $(this).attr("name")).data(
				"input-chosen",
				$(this).attr("id")
			);
			_this.resetNextOptions();
		});

		$(".custom-lens__progress__item").click(function() {
			const stepNumber = $(this).data("step-number");
			_this.setStep(stepNumber);
		});

		$("body").on("click", ".custom-lens__step__next", () => {
			const actualStep = $(".custom-lens__steps").data("actual-step");
			_this.setStep(actualStep + 1);
		});

		$("body").on("click", ".custom-lens__step__prev", () => {
			const actualStep = $(".custom-lens__steps").data("actual-step");
			_this.setStep(actualStep - 1);
		});

		$(".custom-lens__edit").click(() => {
			$(".custom-lens__result").removeClass("active");
			$(".custom-lens__steps").addClass("active");
		});

		$(".custom-lens__buy").click(() => {
			_this.getChosenSkus(true);
		});

		$(".custom-lens-started, .custom-lens__header__close").click(() => {
			$(".wrapper-lens").toggleClass("active");
			$(".content-produto").toggle();
		});

		$("#open-photo-modal").on("click", () => {
			$(".photo-modal").addClass("active");
		})

		$("#close-photo-modal").on("click", () =>{
			$(".photo-modal").removeClass("active");
		})

		$(".continue-prescription").on("click", () =>{
			let selectedLens = $(
				'.custom-lens  input[id*="result__option"]:checked'
			);

			if (selectedLens.val() && window.skuJson.available){
				$(".custom-lens__result").removeClass("active");
				$(".custom-lens__prescription").addClass("active");
			}else {
				this.feedbackMessage("Selecione uma lente.", "error");
			}
		})

		$(".custom-lens__edit__prescription").on("click", () =>{
			$(".custom-lens__prescription").removeClass("active");
			$(".custom-lens__steps").addClass("active");
		})

		$('#input-prescription').on("change", (e) =>{
			let fileNamePrescription =  e.target.files[0].name;
			let fileNamePhoto = $('#input-photo').prop('files')[0];
			let cep = this.validateCep($('#input-cep').val());

			$(".custom-lens__prescription__send-prescription__send-action .selected-file span").text(fileNamePrescription);
			if(fileNamePhoto && fileNamePrescription && cep){
				let extensionValidator = new RegExp(
					"(.*?).(png|jpg|jpeg|pdf|bmp|tif|tiff)$"
				);
				if(!extensionValidator.test(fileNamePhoto.name) || !extensionValidator.test(fileNamePrescription)){
					this.feedbackMessage(
						"Extensão do arquivo inválida.",
						"error"
					);
					$(".custom-lens__buy").prop("disabled", true);
				}else{
					$(".custom-lens__buy").prop("disabled", false);
				}
			}else{
				$(".custom-lens__buy").prop("disabled", true);
			}
		})

		$('#input-photo').on("change", (e) =>{
			let fileNamePhoto =  e.target.files[0].name;
			let fileNamePrescription = $('#input-prescription').prop('files')[0];
			let cep = this.validateCep($('#input-cep').val());

			$(".custom-lens__prescription__send-photo__send-action .selected-file span").text(fileNamePhoto)
			if(fileNamePhoto && fileNamePrescription && cep){
				let extensionValidator = new RegExp(
					"(.*?).(png|jpg|jpeg|pdf|bmp|tif|tiff)$"
				);
				if(!extensionValidator.test(fileNamePhoto) || !extensionValidator.test(fileNamePrescription.name)){
					this.feedbackMessage(
						"Extensão do arquivo inválida.",
						"error"
					);
					$(".custom-lens__buy").prop("disabled", true);
				}else{
					$(".custom-lens__buy").prop("disabled", false);
				}
			}else{
				$(".custom-lens__buy").prop("disabled", true);
			}
		})

		$('#input-cep').on('change',(e)=>{
			let cep = this.validateCep($(e.target).val());


			let fileNamePhoto = $('#input-photo').prop('files')[0];
			let fileNamePrescription = $('#input-prescription').prop('files')[0];
			
			if(fileNamePhoto && fileNamePrescription && cep){
				let extensionValidator = new RegExp(
					"(.*?).(png|jpg|jpeg|pdf|bmp|tif|tiff)$"
				);

				if(!extensionValidator.test(fileNamePhoto.name) || !extensionValidator.test(fileNamePrescription.name)){
					this.feedbackMessage(
						"Extensão do arquivo inválida.",
						"error"
					);
					$(".custom-lens__buy").prop("disabled", true);
				}else{
					$(".custom-lens__buy").prop("disabled", false);
				}
			}else{
				$(".custom-lens__buy").prop("disabled", true);
			}
		})
	}

	createPopUp(element) {
		const popUp = `
			<div class="wrapper-lens">
				<div class="custom-lens">
					<div class="custom-lens__header">
						<div class="custom-lens__header__name">
							<span class="brand-name"></span>
							<span class="name-product">${window.skuJson.name}</span>
						</div>

						<button class="custom-lens__header__close">
							Fechar
						</button>
					</div>
					<div class="custom-lens__choice">

						<ul class="custom-lens__choice__list">
							<li>
								<input
									id="choice__option__with" type="radio"
									value="com-grau" name="choice__option__grade"
								/>
								<label for="choice__option__with">Com grau <span>Para perto, longe ou multifocal</span></label>
							</li>
							<li>
								<input
									id="choice__option__without" type="radio"
									value="sem-grau" name="choice__option__grade"
								/>
								<label for="choice__option__without">Sem grau <span>Lentes de policarbonato sem grau</span></label>
							</li>
						</ul>
						<div class="custom-lens__choice__atencao">
							<i class="sprite sprite-alert-octagon"> </i>
							<span>Por favor, <strong>selecione uma opção</strong> para prosseguir.</span>
						</div>
						<div class="test"></div>
							<button class="choice__option__grade showWarning" disabled>
								<span>Continuar</span>
								<i class="sprite sprite-arrow-right-white"></i>
							</button>

					</div>
					<div class="custom-lens__warning">
						<div class="warning-text">
							<h2>Atenção</h2>
							<h3>Em nosso site temos um limite de disponibilidade de grau. Confira abaixo:</h3>
							<div>
								<span>Limite de disponibilidade de dioptria Visão Simples:</span>
								<p>  Esférico de -0,25 à -4,00</p>
								<p>  Esférico de +0,25 à +4,00</p>
								<p>  Cilíndrico até -2,00</p>
							</div>
							<div>
								<span>Limite de disponibilidade de dioptria Multifocal:</span>
								<p>  Esférico de -0,25 à -4,00</p>
								<p>  Esférico de +0,25 à +4,00</p>
								<p>  Cilíndrico até -2,00 Adição 3,00</p>
							</div>
						</div>
						<div class="warning-lojas">
						<p>Se o seu grau for maior, entre em contato </br> com uma de <strong>nossas lojas:</strong> <a class="warning-text-link" href="https://www.oticasdiniz.com.br/lojas" target="_blank">ENCONTRE UMA LOJA</a></p>
						</div>

						<button class="custom-lens__choice__next">
							<span>Continuar</span>
						<i class="sprite sprite-arrow-right-white"></i>
						</button>
					</div>
					<div class="custom-lens__steps" data-actual-step="0">
						<ul class="custom-lens__progress">
						${this.specifications
							.map((specification, index) => {
								return `
										<li>
											<button class="custom-lens__progress__item progress-milestone-${index}" data-step-number="${index}" disabled>
												<span>${specification.name}</span>
											</button>
										</li>
										`;
							})
							.join("")}
						</ul>
					</div>

					<div class="custom-lens__result">
						<h3 class="custom-lens__result__title">Escolha sua lente</h3>
						<ul class="custom-lens__result__list"></ul>
						<div class="custom-lens__result__options">
							<button class="custom-lens__edit">

								<span>Editar Lente</span>
							</button>

							<button class="continue-prescription">
								<span>Continuar</span>
								<i class="sprite sprite-arrow-right-white"></i>
							</button>

						</div>
					</div>

					<div class="custom-lens__prescription">

						<span class="custom-lens__prescription__title">Você tem a prescrição? (receita)</span>
						<span class="custom-lens__prescription__subtitle">Precisamos dessas informações para prosseguirmos
						com sua compra!</span>

						<div class="custom-lens__prescription__send-prescription">
							<div class="custom-lens__prescription__send-prescription__send-action">
								<div class="send-btn send-prescription">
									<input id="input-prescription" type="file" style="display:none;"/>
									<label for="input-prescription" class="falseinput" id="falseinput-prescription">
										<i class="sprite sprite-upload-yellow"></i>
										Enviar receita
									</label>
								</div>
								<span class="selected-file" id="selected-file-prescription">
								<i class="sprite sprite-file-selected"></i>
								<span>Nenhum arquivo</span>
								</span>
							</div>
							<span class="custom-lens__prescription__send-prescription__text">
								Para produzirmos as suas lentes, precisamos que você nos envie sua(s) receita(s) oftálmicas.
							</span>
						</div>

						<div class="custom-lens__prescription__send-photo">
							<div class="custom-lens__prescription__send-photo__send-action">
								<div class="send-btn send-photo">
									<input id="input-photo" type="file" style="display:none;"/>
									<label for="input-photo" class="falseinput" id="falseinput-photo">
										<i class="sprite sprite-carbon_drop-photo"></i>
										Enviar foto
									</label>
								</div>
								<span class="selected-file" id="selected-file-photo">
									<i class="sprite sprite-file-selected"></i>
									<span>Nenhum arquivo</span>
								</span>
							</div>
							<span class="custom-lens__prescription__send-photo__text">
								Precisamos da sua foto para fazermos o óculos, aqui um modelo de como deve ser tirada. <strong id="open-photo-modal">clique aqui</strong>
							</span>
						</div>

						<div class="custom-lens__prescription__send-cep">
							<div class="custom-lens__prescription__send-cep__send-action">
								Digite seu CEP para finalizar a compra:
								<input name="cep" type="text" id="input-cep" value="" size="10" maxlength="9"/>
							</div>
						</div>

						<div class="custom-lens__prescription__contact"> 
							<span class="custom-lens__prescription__contact__text">
							Qualquer dúvida entre em contat por e-mail / WhatsApp: <br/>
							<a class="contact-email" href="emailto:atendimentoweb@oticasdiniz.com.br">
							<i class="sprite sprite-codicon_mail"></i>
							atendimentoweb@oticasdiniz.com.br
							</a>
							<a class="contact-phone" href="tel:11989326761">
							<i class="sprite sprite-bi_whatsapp"></i>
							(11) 98932-6761
							</a>
							</span>
						</div>

						<div class="custom-lens__result__options custom-lens__result__options__prescription">

						<button class="custom-lens__edit__prescription">
							<span>Editar Lente</span>
						</button>

						<button class="custom-lens__buy" disabled>
						Finalizar Compra
						<i class="sprite sprite-arrow-right-white"></i>
						</button>
					</div>

					</div>

					<div class="custom-lens__feedback"></div>
				</div>
			</div>
		`;

		const photoModal =
		`
			<div class="photo-modal">
				<button id="close-photo-modal">
					<i class="sprite sprite-close-x"></i>
				</button>

				<span class="photo-modal__title">
					Escolha de armação e lentes oftálmicas
				</span>

				<div class="photo-modal__images-wrapper">
					<div class="instruction">
						<span class="instruction__number">1</span>

						<span class="instruction__text">
							Tire uma foto frontal de seu rosto, como se fosse 3x4 segurando qualquer cartão magnético com a mão direita(crédito, débito, ticket, etc) abaixo do nariz com a tarja preta voltada para lente
						</span>
					</div>

					<div class="instruction">
						<span class="instruction__number">2</span>

						<span class="instruction__text">
						- Tire a foto sem óculos;<br/>
						- Mantenha-se olhando para a frente;<br/>
						- Utilize o flash do seu celular para iluminar bem os seus olhos;<br/>
						</span>
					</div>

					<div class="instruction">
						<span class="instruction__number">3</span>

						<span class="instruction__text">
						Se tiver alguma dúvida, estamos à disposição através do e-mail atendimentoweb@oticasdiniz.com.br ou no telefone (11) 98894-7323
						<br/>Seus óculos somente seguirão para produção após o retorno e validação da sua receita e de suas medidas.
						<br/>Estamos no aguardo.
						</span>
					</div>

					<div className="instruction__images">
						<img src="https://oticasdiniz.vteximg.com.br/arquivos/photo-modal1.png">
						<img src="https://oticasdiniz.vteximg.com.br/arquivos/photo-modal2.png">
					</div>
				</div>
			</div>
		`;

		$(element).append(popUp);
		this.createSpecificationsSteps();

		$(".product-main .container").append(photoModal);

		$(".product-main .product-buy").addClass("custom-lens-started");

		$('#input-cep').mask('00000-000');
	}

	createSpecificationsSteps() {
		this.specifications.map(async (specification, index) => {
			let step = await this.createStep(specification, index);
			$(".custom-lens__steps").append(`${step}`);
		});
	}

	async createStep(specification, index) {
		let StandartValues = await this.getSpecificationValues(
			specification.id
		);
		let values = StandartValues.sort(this.sortItems.bind(this));

		const groupName = "step__" + this.textoParaNomeCss(specification.name);
		let masterData = new MasterData(specification.descriptionMD);

		let descricaoPromise = masterData.search({
			_fields: "posicao,descricao",
			_sort: "posicao ASC"
		});
		let descricaoJson = await (await descricaoPromise).json();

		let step = `
			<div class="custom-lens__step step-number-${index}">
				<button data-step-number="${index -
					1}" class="custom-lens__step__prev ${groupName}" style="display:${
			index > 0 ? "block" : "none"
		}">
					<i class="sprite sprite-seta-voltar-produto"></i>
					<span>VOLTAR</span>
				</button>
				<ul class="custom-lens__step__list">
					${values
						.map(value => {
							const valueId =
								"step__option__" + value.FieldValueId;
							let item = `
							<li>
								<input
									id="${valueId}" type="radio"
									data-specification-id="${specification.id}"
									data-descricao-md="${specification.descriptionMD}"
									value="${value.Value}" name="${groupName}"
								/>
								<label for="${valueId}">
									<div class="step__option__box__name">
										<p class="step__option__name">${value.Value}</p>
									</div>
									${
										descricaoJson[value.Position - 1]
											? `
									<div class="step__option__box__tooltip">
										<p class="tooltip">?</p>
										<div class="tooltip__description">
											<p> ${descricaoJson[value.Position - 1].descricao} </p>
										</div>
									</div>
									`
											: ``
									}
								</label>
							</li>
							`;
							return item;
						})
						.join("")}
				</ul>
				<button data-step-number="${index}" class="custom-lens__step__next ${groupName}" disabled>
					<span>Continuar</span>
					<i class="sprite sprite-arrow-right-white"></i>
				</button>
			</div>
		`;

		return step;
	}

	sortItems(a, b) {
		if (a.Position < b.Position) {
			return -1;
		}
		if (a.Position > b.last_nom) {
			return 1;
		}
		return 0;
	}

	getSpecificationValues(specId) {
		const jqXHR = $.ajax({
			url: "/api/catalog_system/pub/specification/fieldvalue/" + specId,
			type: "GET"
		});

		return new Promise(resolve => {
			jqXHR.done(function(data) {
				resolve(data);
			});
		});
	}

	async createProductList() {
		$(".custom-lens__result__list").empty();
		$(".custom-lens__steps").removeClass("active");
		$(".custom-lens__result").addClass("active");

		let searchParameter = this.getProductSearchParameter();

		if (searchParameter) {
			let values = await this.getProductBySpecifications(searchParameter);
			let productList = "";;

			if (values.length) {
				productList = `
					${values
						.map(value => {
							const groupName = "result__" + value.productId;
							const valueId =
								"result__option__" + value.productId;
							let skuPrice =
								value.items[0].sellers[0].commertialOffer.Price;
							let skuQuantity =
								value.items[0].sellers[0].commertialOffer
									.AvailableQuantity;

							let item = `
							<li>
								<input
									id="${valueId}" type="radio"
									data-seller="${value.items[0].sellers[0].sellerId}"
									value="${value.items[0].itemId}" name="${groupName}"
								/>
								<label for="${valueId}" ${skuQuantity > 0 ? "" : "disabled"}>
									<span class="result__option__name">${value.productName}</span>
									<span class="result__option__description">${value.description}</span>
									<span class="result__option__price">
										${
											skuQuantity > 0
												? "R$" + this.getPrice(skuPrice)
												: '<span class="sould-out">indisponível</span>'
										}
									</span>
								</label>
							</li>
							`;
							return item;
						})
						.join("")}
				`;

				productList += `
					<p class="alert">
					Após a confirmação de pagamento você irá receber <strong>um e-mail</strong> com as orientações para enviar a foto da receita de seus óculos. <br/> <br/>
					Qualquer dúvida entre em contato pelo e-mail:<br/>
					<a href="mailto:lentes@oticasdiniz.com.br"> <strong>lentes@oticasdiniz.com.br </strong></a>
						<br/>
						ou por <strong>Whatsapp: <a  href="https://api.whatsapp.com/send?phone=5511989326761" target="_blank">(11) 98932-6761 </a></strong>
					</p>
				`;
			} else {
				productList = `
					<li class="empty">
						Infelizmente não encontramos nenhuma lente com as especificações informadas, tente alterar os filtros de busca.
					</li>
				`;
			}

			$(".custom-lens__result__list").append(`${productList}`);
		}
	}

	getProductBySpecifications(searchParameter) {
		// console.log(searchParameter);
		const jqXHR = $.ajax({
			url: "/api/catalog_system/pub/products/search?" + searchParameter,
			type: "GET"
		});

		return new Promise(resolve => {
			jqXHR.done(function(data) {
				resolve(data);
			});
		});
	}

	getProductSearchParameter() {
		let selectedInputs = $(
			'.custom-lens input[id*="step__option__"]:checked'
		);
		let searchParameters = "";

		if (selectedInputs.length) {
			selectedInputs.map((index, input) => {
				searchParameters +=
					"fq=specificationFilter_" +
					$(input).data("specification-id") +
					":" +
					$(input).val() +
					"&";
			});
			// searchParameters += `fq=specificationFilter_${
			// 	this.tamanhoLentesId
			// }:${this.GetProductTamanhoLente()}&`;
		}

		return searchParameters;
	}

	async spotValidOptions() {
		let searchParameters = "";
		let foundOptions;
		if (this.previousStep < 0) {
			foundOptions = $('.step-number-0 input[id*="step__option__"]');

			if (foundOptions.length) {
				await Promise.all(foundOptions.map(async (index, input) => {
					searchParameters =
						"fq=specificationFilter_" +
						$(input).data("specification-id") +
						":" +
						$(input).val() +
						"&";

					let values = await this.getProductBySpecifications(
						searchParameters
					);

					if (values.length) {
						const parent = $(input).closest('li');

						const label = $(input).siblings("label");
						const boxName = label.find(".step__option__box__name");
						let bestPrice = this.getOptionPrice(values);


						$(parent).attr("data-maxvalue", bestPrice)

						label.find("span").remove();

						$("<span />", {
							class: "step__option__price",
							text: `A partir de R$ ${this.getPrice(bestPrice)}`
						}).appendTo(boxName);

						$(input).addClass("valid-option");
					}
				}))
			}
		} else if (this.previousStep < this.actualStep) {
			let chosenOption = $(
				".step-number-" +
					this.actualStep +
					' input[id*="step__option__"]:checked'
			);

			if (!chosenOption.length) {
				foundOptions = $(
					".step-number-" +
						this.actualStep +
						' input[id*="step__option__"]'
				);
				let soFarChosen = this.getProductSearchParameter();

				if (foundOptions.length) {
					await Promise.all(
						foundOptions.map(async (index, input) => {
						searchParameters =
							soFarChosen +
							"fq=specificationFilter_" +
							$(input).data("specification-id") +
							":" +
							$(input).val() +
							"&";

						let values = await this.getProductBySpecifications(
							searchParameters
						);

						if (values.length) {
							const parent = $(input).closest('li');

							const label = $(input).siblings("label");
							const boxName = label.find(
								".step__option__box__name"
							);
							let bestPrice = this.getOptionPrice(values);

							$(parent).attr("data-maxvalue", bestPrice)

							label.find("span").remove();

							$("<span />", {
								class: "step__option__price",
								text: `A partir de R$ ${this.getPrice(
									bestPrice
								)}`
							}).appendTo(boxName);

							$(input).addClass("valid-option");
						}
					}))
				}
			}
		}
		this.orderLensList(foundOptions);
	}

	getOptionPrice(values) {
		const prices = values.map(
			value => value.items[0].sellers[0].commertialOffer.Price
		);
		return Math.min(...prices);
	}

	setStep(stepNumber) {
		this.previousStep = this.actualStep;
		this.actualStep = stepNumber;
		this.spotValidOptions();

		if (stepNumber < this.specifications.length) {
			$(".progress-milestone-" + stepNumber)
				.addClass("active")
				.prop("disabled", false);
			$(".custom-lens__steps").data("actual-step", stepNumber);
			$(".custom-lens__step").removeClass("active");
			$(".step-number-" + stepNumber).addClass("active");
		} else {
			this.createProductList();
		}
	}

	resetNextOptions() {
		for (let i = this.actualStep + 1; i < this.specifications.length; i++) {
			let step = $(".step-number-" + i);
			step.find("input")
				.removeClass("valid-option")
				.prop("checked", false);
			step.find(".custom-lens__step__next").prop("disabled", true);
			$(".progress-milestone-" + i)
				.removeClass("active")
				.prop("disabled", true);
		}
	}

	getChosenSkus(comGrau) {
		let $this = this;

		if (comGrau) {
			let selectedLens = $(
				'.custom-lens  input[id*="result__option"]:checked'
			);

			if (selectedLens.val() && window.skuJson.available) {

				let chosenSkus = [];
				let noAdd = false;
				let sameLens  = false;
				let qttCartLens;
				let qttCart;

				vtexjs.checkout.getOrderForm().done(function(orderForm) {
					vtexjs.catalog.getProductWithVariations(window.skuJson.productId).done(function(product){
						if (orderForm.items.length > 0) {
							$.each(orderForm.items, function (indexInArray, valueOfElement) {
								if (valueOfElement.id === selectedLens.val()) {
									sameLens = true;
									qttCartLens = valueOfElement.quantity
								}
								if (valueOfElement.id === window.skuJson.skus[0].sku.toString()) {
									noAdd = true;
									qttCart = valueOfElement.quantity
								}
							});
							if (noAdd) {
								let addQtt = qttCart + 1;
								let addQttLens = qttCartLens + 1;
								if (product.skus[0].availablequantity > 1 && addQtt <= product.skus[0].availablequantity) {
									chosenSkus = [
										{
											id: window.skuJson.skus[0].sku,
											quantity: addQtt <= product.skus[0].availablequantity ? addQtt : product.skus[0].availablequantity,
											seller: window.skuJson.skus[0].sellerId
										},
										{
											id: selectedLens.val(),
											quantity: addQttLens ? addQttLens : 1,
											seller: selectedLens.data("seller")
										}
									];
									$this.addPrescription(chosenSkus);
								} else {
									$this.feedbackMessage("Não temos armações disponiveis p/ quantidade de lentes", "error");
								}
								if (product.skus[0].availablequantity <= 1) {
									$this.feedbackMessage("Temos apenas uma armação, não vendemos lentes a parte..", "error");
								}
							} else {
								chosenSkus = [
									{
										id: window.skuJson.skus[0].sku,
										quantity: 1,
										seller: window.skuJson.skus[0].sellerId
									},
									{
										id: selectedLens.val(),
										quantity: 1,
										seller: selectedLens.data("seller")
									}
								];
								$this.addPrescription(chosenSkus);
							}
						} else {
							chosenSkus = [
								{
									id: window.skuJson.skus[0].sku,
									quantity: 1,
									seller: window.skuJson.skus[0].sellerId
								},
								{
									id: selectedLens.val(),
									quantity: 1,
									seller: selectedLens.data("seller")
								}
							];
							$this.addPrescription(chosenSkus);
						}
					});
				});
			} else {
				$this.feedbackMessage("Selecione uma lente.", "error");
			}
		} else {
			
			if (window.skuJson.available) {
				let chosenSkus = [
					{
						id: window.skuJson.skus[0].sku,
						quantity: 1,
						seller: window.skuJson.skus[0].sellerId
					}
				];
				$this.addProductsToCart(chosenSkus);
			} else {
				console.log("Produto indisponível.");
			}
		}
	}

	async addProductsToCart(chosenSkus, cep)
	{
		const _this= this;
		let seller = '1';
		if(cep){
			const sellerChain = await this.getSeller(chosenSkus, cep);
			seller = sellerChain[sellerChain.length - 1];
		}
		const items = chosenSkus.map((sku)=> `sku=${sku.id}&qty=${sku.quantity}&seller=${seller}`).join('&')
		await fetch(`/checkout/cart/add/?${items}&sc=1`,
		{
			"headers": {"Content-Type": "application/json", "Accept": "application/json"},
			"method": "POST"
		}).then((resp) =>{
			_this.feedbackMessage(
				"Produto adicionado ao carrinho com sucesso!",
				"success"
			);
			window.location.href = "/checkout/";
		}).catch((error)=>{
			_this.feedbackMessage(
				"Não foi possível adicionar o produto ao carrinho.",
				error
			);
		})
	}

	addPrescription(chosenSkus){
		const prescription = $("#input-prescription").prop('files')[0];
		const photo = $("#input-photo").prop('files')[0];
		const cep = $('#input-cep').val();

		return vtexjs.checkout.getOrderForm().done((orderForm)=>{
			const prescriptionBody = {
				orderId: orderForm.orderFormId
			}
			return this.prescriptionMD.post(prescriptionBody).then((resp) => {
				return resp.json();
			}).then(async (resp) => {
				await this.sendAttachment("prescription", prescription, resp.DocumentId);
				await this.sendAttachment("photo", photo, resp.DocumentId);
				await this.setOrderFormCustomData(resp.DocumentId, orderForm.orderFormId);
				this.addProductsToCart(chosenSkus, cep);
			}).catch(e =>{console.log("Error ao upar o arquivo", e)})
		})
	}

	feedbackMessage(message, status) {
		let feedback = `
			<div class="custom-lens__feedback__msg ${status}">
				${message}
			</div>
		`;

		$(".custom-lens__feedback").empty();
		$(".custom-lens__feedback").append(feedback);

		setTimeout(
			function() {
				$(".custom-lens__feedback__msg").fadeOut("slow");
				setTimeout(() => {
					$(".custom-lens__feedback__msg").remove();
				}, 400);
			}.bind(this),
			3000
		);
	}

	textoParaNomeCss(texto) {
		if (typeof texto == "undefined") {
			return "";
		}

		texto = texto
			.toLowerCase()
			.replace(/\)|\(/g, "")
			.replace(/\./g, "")
			.replace(/,/g, "")
			.replace(/ /g, "-")
			.replace(/\//g, "_")
			.replace(/[áàâã]/g, "a")
			.replace(/[ìíĩî]/g, "i")
			.replace(/[éèê]/g, "e")
			.replace(/[óòôõ]/g, "o")
			.replace(/[úùû]/g, "u")
			.replace(/[ç]/g, "c")
			.replace(/[^A-Za-z0-9_-]/g, "");
		return texto;
	}

	getPrice(price) {
		if (isNaN(price)) {
			price = parseFloat(
				price
					.replace("R$", "")
					.replace(".", "")
					.replace(",", ".")
			);
			return parseFloat(price);
		} else {
			price = (price*2) || 0;
			price = price.toLocaleString("pt-BR", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			});

			return price;
		}
	}
	orderLensList(element){
		const sortedElement = $(element).closest('li').toArray().sort((a, b)=>{
			const valuea = $(a).data('maxvalue') || 0
			const valueb = $(b).data('maxvalue') || 0
			if(valuea < valueb) return -1;
			if(valuea > valueb) return 1;
			return 0 ;
		})
		console.log(sortedElement,"sortedElement")
		$(sortedElement).parent().html(sortedElement)
	}

	obterCannalDeVendas() {
		var name = "VTEXSC=sc=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") c = c.substring(1);
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return 1;
	}

	GetProductTamanhoLente() {
		if (!this.tamanhoLente) {
			try {
				const data = $.ajax({
					url: `/api/catalog_system/pub/products/search?fq=productId:${skuJson.productId}`,
					type: "GET",
					async: false
				}).responseJSON;

				const data_tamanho = data[0]["Tamanho de Lentes"];
				this.tamanhoLente = data_tamanho ? data_tamanho[0] : "Padrão";
			} catch (error) {
				console.error("Falha ao obter tamanho da lente da armacao");
			}
		}
		console.log("Tamanho da Lente:", this.tamanhoLente);
		return this.tamanhoLente;
	}

	getBrandName() {
		$(".custom-lens__header__name .brand-name").append(
			$(".brandName").text()
		);
	}

	sendAttachment(field, file, documentId){
		let data = new FormData();
		data.append(field, file);

		return this.prescriptionMD.sendAttachment(documentId, field,data)
	}

	setOrderFormCustomData(documentId, orderFormId){
		return fetch(
			`/api/checkout/pub/orderForm/${orderFormId}/customData/receitacustomdata`,
			{
				"method": "PUT",
				"body": JSON.stringify({
					"documentId": documentId,
					"temReceita": "Sim"
				}),
				"headers": {"Content-Type": "application/json", "Accept": "application/json"}
			},
		).catch((err)=>{
			console.log("err", err);
		})
	}

	getSeller(chosenSkus, cep){
		return fetch(
			`/api/checkout/pub/orderForms/simulation`,
			{
				"headers": {"Content-Type": "application/json", "Accept": "application/json"},
				"method": "POST",
				"body": JSON.stringify({
					"items":
						chosenSkus.map((sku)=>{
							return {
								"id": sku.id,
								"quantity": sku.quantity,
								"seller": sku.seller
							}
						}),
					"country": "BRA",
					"postalCode": cep
				})
			}
		).then(resp => resp.json()).then((resp) => resp.items[0].sellerChain);
	}

	validateCep(cep){
		const cleanCep = cep.replace("-", "").replace("_", "").trim();

		if (cleanCep.length === 8) {
			return true;
		}

		return false;
	}
}
