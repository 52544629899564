export default class Camapanha {
	constructor() {
		this.Campanha();
		
	}
    Campanha(){
        $(".campanha01.mobile p").on("click", function (e) { 
            e.preventDefault();
            $(".campanha01.mobile ul").toggleClass('ativo')
        });
        $(".campanha02.mobile p").on("click", function (e) { 
            e.preventDefault();
            $(".campanha02.mobile ul").toggleClass('ativo')
        });
    }
}