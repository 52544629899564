export class M3Dropdown {
	constructor(options = {}) {
		//**EXEMPLO DE OPTIONS */
		// this.options = {
		// 	placeholder: 'Placeholder',
		//  selectFirstAsDefault: false,
		// 	title: 'Selecione:',
		// 	class: 'classe-1 classe-02',
		// 	values: [{
		// 			display: 'valor 1',
		// 			value: '1'
		// 		},
		// 		{
		// 			display: 'valor 2',
		// 			value: '2'
		// 		}
		// 	]
		// }

		this.options = options;
		return this._init();
	}

	_init() {
		this.$dropdown = this._createElement();
		this._selectors();
		this._events();
		this._configure();
		return this.$dropdown;
	}

	_selectors() {
		this.$title = this.$dropdown.find("m3-dropdown-title");
		this.$title_value = this.$dropdown.find(
			"m3-dropdown-title .selected_value"
		);
		this.$values = this.$dropdown.find("m3-dropdown-item");
	}

	_createElement() {
		const createItem = item => {
			if (item.availablequantity === 0) {
				return `<m3-dropdown-item value="${item.value}" class="${
					item.availablequantity >= 1 ? "available" : "notAvailable"
				}">${item.display}</m3-dropdown-item>`;
			}
			return `<m3-dropdown-item value="${item.value}" >${item.display}</m3-dropdown-item>`;
		};

		const el = `
			<m3-dropdown value="">
				<m3-dropdown-title>
					<span class="title">${this.options.title || ""}</span>
					<span class="selected_value">${this.options.placeholder || ""}</span>
					<i class="sprite sprite-arrow-down-gray"></i>
				</m3-dropdown-title>

				<m3-dropdown-items>
					${this.options.values.map(createItem).join("")}
				</m3-dropdown-items>
			</m3-dropdown>
		`;
		return $(el);
	}

	_events() {
		const clickOnItem = ev => {
			const target = $(ev.target);
			const value = target.attr("value");
			this.$values.removeClass("selected");
			target.addClass("selected");
			//Atualiza o titulo do dropdown
			this.$title_value.text(target.text());
			//Adiciona o valor selecionado no dropdown
			this.$dropdown[0].value = value;
			this.$dropdown.attr("value", value);
			this.$dropdown.trigger("change");
			this._close();
		};

		const clickOnTitle = ev => {
			if (this.$dropdown.hasClass("open")) {
				this._close();
			} else {
				$("m3-dropdown")
					.not(this.$dropdown)
					.removeClass("open");
				this._open();
			}
		};

		this.$values.on("click", clickOnItem.bind(this));
		this.$title.on("click", clickOnTitle.bind(this));
	}

	_configure() {
		//Adiciona classes da configuracao ao elemento de dropdown
		this.$dropdown.addClass(this.options.class);

		if (this.options.selectFirstAsDefault) {
			this.$dropdown
				.find("m3-dropdown-item")
				.first()
				.trigger("click");
		}
	}

	_open() {
		this.$dropdown.addClass("open");
	}

	_close() {
		this.$dropdown.removeClass("open");
	}
}
