import OverLay from "../Overlay";

export default class ReceitaMedicaoPopup {
	constructor() {
		this.popup = this.draw();
		this.overlay = new OverLay(16, "rgba(0,0,0, 0.5)");
		this.events();
	}

	events() {
		this.popup
			.find(".popup-medicao__close")
			.click(this.closePopup.bind(this));
		this.overlay.registerClickEvent(this.closePopup.bind(this));
		$("body").on("click", "#medicao-popup", this.openPopup.bind(this));
	}

	openPopup() {
		this.popup.addClass("active");
		this.overlay.show();
	}

	closePopup() {
		this.popup.removeClass("active");
		this.overlay.hide();
	}

	draw() {
		let html = `
            <div class="popup-medicao">
                <div class="popup-medicao__container">
                    <div class="popup-medicao__close"></div>
                    <div class="popup-medicao__content">
                        <img src="/arquivos/orientacao.png?v=01" />
                    </div>
                </div>
            </div>
        `;
		let popup = $(html).appendTo("body");
		return popup;
	}
}
