import { LentesContatoView } from "./lentes-contato-view";

export class LentesContatoModule {
	constructor() {
		this.init();
	}

	async configure() {
		this.options = {
			salesChannel: 1
		};
		this.viewOpts = {
			qtd: skuJson.skus[0].availablequantity || 24,
			graus: await this.generateGraus()
		};
	}

	async init() {
		await this.configure();
		this.view = new LentesContatoView(this.viewOpts).init();
		this.selectors();
		await this.initializeVtexOrderForm();
		this.events();
		this.$buyButton.addClass("not-customizable");
	}

	events() {
		this.view.onChange(this.viewChangeHandler);
		this.$buyButton.on("click", this.buyHandler.bind(this));
	}

	selectors() {
		this.$buyButton = $(".buy-button");
	}

	// Event Handlers

	viewChangeHandler(ev, selectedValues) {
		console.log("Lentes selecionadas:", selectedValues);
	}

	async buyHandler(ev) {
		ev.preventDefault();
		try {
			const lentes = this.view.getSelectedValues();
			const cartItens = this.generateCartItens(lentes);
			if (this.canBuy(lentes)) {
				// await this.clearCart();
				this.addTocart(cartItens);
			}
		} catch (error) {
			console.error(error);
			this.view.feedbackMessage(
				"Erro ao adicionar produto ao carrinho",
				"error"
			);
		}
	}

	// Methods

	addTocart(cartItens) {
		const onDone = ev => {
			this.view.feedbackMessage(
				"Produto adicionado ao carrinho com sucesso!"
			);
			$(".product .pop-up").toggleClass("ativo");
		};
		const onFail = (error, message) => {
			console.error(error);
			this.view.feedbackMessage(
				"Não foi possível adicionar o produto ao carrinho.",
				"error"
			);
		};

		try {
			window.vtexjs.checkout
				.addToCart(cartItens, null, this.options.salesChannel)
				.done(onDone.bind(this))
				.fail(onFail.bind(this));
		} catch (error) {
			console.warn("Erro ao adicionar skus ao carrinho de compra");
			console.error(error);
			onFail();
		}
	}

	//Remove os produtos do orderForm para evitar que o produto duplique no checkout
	async clearCart() {
		const sku = window.skuJson.skus[0].sku;
		const index = await this.getItemIndex(sku);
		const itemsToRemove = index.map(idx => {
			return {
				index: idx,
				quantity: 0
			};
		});

		if (itemsToRemove.length) {
			return await vtexjs.checkout.removeItems(itemsToRemove);
		}
	}

	//Gera o array 'items' do orderform com a quantidade e anexo de cada lente
	generateCartItens(lentes) {
		const sellerId = window.skuJson.skus[0].sellerId;

		const buildCartItem = lente => {
			if (!lente) {
				return;
			}
			return {
				id: lente.skuId,
				quantity: lente.qtd,
				seller: sellerId
			};
		};

		const items = [];
		if (lentes.lente_direita) {
			items.push(buildCartItem(lentes.lente_direita));
		}
		if (lentes.lente_esquerda) {
			items.push(buildCartItem(lentes.lente_esquerda));
		}

		return items;
	}

	async initializeVtexOrderForm() {
		try {
			return await window.vtexjs.checkout.getOrderForm();
		} catch (error) {
			console.error(error);
			this.view.feedbackMessage(
				"Falha ao se comunicar com a vtex",
				"error"
			);
		}
	}

	canBuy(lentes) {
		//Verifica se a lente unica foi selecionada
		if (this.view.isLenteUnica()) {
			if (lentes.lente_direita.grau) {
				return true;
			} else {
				this.view.feedbackMessage("Selecione as lentes", "error");
				return false;
			}
		}
		//Verifica se as lentes esquerdas e direitas foram selecionadas
		if (lentes.lente_esquerda.grau && lentes.lente_direita.grau) {
			return true;
		} else {
			this.view.feedbackMessage("Selecione todas as lentes", "error");
		}

		return false;
	}

	//Retorna os index do item selecionado no orderForm
	async getItemIndex(skuId) {
		const orderForm = await vtexjs.checkout.getOrderForm();

		const indices = [];
		for (let i = 0; i < orderForm.items.length; i++) {
			const item = orderForm.items[i];
			if (item.id == skuId) {
				indices.push(i);
			}
		}
		return indices;
	}

	async GetProductDioptrias() {
		const data = [];
		skuJson.skus.map(({ skuname: name, availablequantity, sku }) => {
			// name = new Intl.NumberFormat('pt-br',{ minimumFractionDigits: 2 }).format(name/100);
			// if(!name.startsWith("-")){
			// 	name= "+" + name;
			// }

			data.push({ name, sku, availablequantity });
		});
		return data;
	}

	async generateGraus() {
		const data = await this.GetProductDioptrias();
		const graus = data.map(({ name, sku, availablequantity }) => {
			const grau = {
				value: sku,
				display: name,
				availablequantity: availablequantity
			};
			return grau;
		});
		return graus;
	}

	// async generateGraus() {
	// 	const opts = {
	// 		min: -12.00,
	// 		max: 8.00,
	// 		step: 0.25
	// 	};

	// 	const graus = [];
	// 	let next_value = opts.min;
	// 	while (next_value <= opts.max && next_value >= opts.min) {
	// 		const formated_value = (next_value > 0 ? '+' : '') + next_value.toFixed(2).replace('.', ',')
	// 		const grau = {
	// 			value: formated_value,
	// 			display: formated_value,
	// 		}
	// 		if (next_value != 0) {
	// 			graus.push(grau);
	// 		}
	// 		next_value += opts.step;
	// 	}
	// 	return graus;
	// }

	// async addAnexo(skuId, lentes) {
	// 	const itemIndex = await this.getItemIndex(skuId);
	// 	const attachmentName = "Lentes de contato";
	// 	const content = {
	// 		['graus_lente_esquerda']: lentes.lente_esquerda.grau,
	// 		['graus_lente_direita']: lentes.lente_direita.grau,
	// 	};

	// 	console.log(itemIndex, attachmentName, content);
	// 	window.vtexjs.checkout.addItemAttachment(itemIndex, attachmentName, content, null, false).done(console.log).fail(console.error);
	// }
}
