jQuery(document).ready(function(){
  scripts.init();
});

var scripts = {
  init: function() {
    this.validate();
    this.allPage();
  },
  allPage: function() {
    $('.deolho-main .bt-expand').on('click', function(event){
      event.preventDefault();
      $(this).parent().addClass('on');
    });
  },
  validate: function() {
    switch ($(".deolho-main #me").val()) {
      case 'home': {
        this.home();
        break;
      }
      default: {
        break;
      }
    }
  },
  home: function(){
   
  }
}