export default class AdvantageBar {
	constructor() {
		this.selectors();
		this.createAdvantageBarSlider();
	}

	selectors() {
		this.AdvantageBarList = $(".advantage-bar");
	}

	createAdvantageBarSlider() {
		this.AdvantageBarList.slick({
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: false,
			responsive: [
				{
					breakpoint: 1000,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						autoplay: true,
						autoplaySpeed: 4000,
						arrows: false,
						dots: false
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						autoplay: true,
						autoplaySpeed: 4000,
						arrows: false,
						dots: false
					}
				},
				{
					breakpoint: 490,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: true,
						autoplaySpeed: 4000,
						arrows: false,
						dots: false
					}
				}
			]
		});
	}
}
