// import OverLay from "../components/Overlay";

import { isSmallerThen768 } from "Helpers/MediasMatch";
export default class Menu {
	constructor() {
		this.selectors();
		// this.overlay = new OverLay(1, "rgba(0,0,0, 0.5)");
		this.events();
	}

	selectors() {
		this.btnFooter = $(".footer-toggle");
		this.btnFooterMob = $(".footer-toggle.mob");
		this.btnFooterSearch = $(".footer-search");
		this.btnFooterUser = $(".footer-user");
	}

	events() {
		if (isSmallerThen768) {
			this.btnFooter.on("click", this.toggleFooter.bind(this));
			this.btnFooterMob.on("click", this.toggleFooter.bind(this));

			$(".footer-toggle-seguranca#mob").on("click", function(e) {
				e.preventDefault();
				$(".footer-toggle-seguranca#mob").toggleClass("ativo");
			});

			$(".footer-toggle-pagamento#mob").on("click", function(e) {
				e.preventDefault();
				$(".footer-toggle-pagamento#mob").toggleClass("ativo");
			});
		}

		this.btnFooterSearch.on("click", this.toggleFooterSearch.bind(this));
		this.btnFooterUser.on("click", this.toggleFooterUser.bind(this));
		// this.overlay.registerClickEvent(this.closeFooterUser.bind(this));
		this.positionScreen();
	}

	toggleFooter(e) {
		$(e.target)
			.siblings()
			.slideToggle();
	}

	toggleFooterSearch() {
		$(".chat-flutuante .searchBox").slideToggle();
	}

	toggleFooterUser() {
		$(".chat-flutuante .userBox").toggleClass("toggle");
		// this.overlay.show();
	}

	closeFooterUser() {
		// this.overlay.hide();
		$(".chat-flutuante .userBox").removeClass("toggle");
	}

	positionScreen() {
		let offset = 400;

		$("body").scroll(function() {
			if ($(this).scrollTop() > offset) {
				$(".chat-flutuante").addClass("show");
			} else {
				$(".chat-flutuante").removeClass("show");
			}
		});
	}
}
