import receitaStore from "./ReceitaStore";
import MasterData from "Helpers/MasterData";

export default class ReceitaService {
	constructor() {
		this.store = receitaStore;
		this.clientMD = new MasterData("CL");
		this.receitaMD = new MasterData("RE");

		this.events();
	}

	events() {
		// this.store.events.subscribe("getUserId", this.getUserId.bind(this));

		this.store.events.subscribe(
			"createReceita",
			this.createReceita.bind(this)
		);

		this.store.events.subscribe(
			"verifyReceitaExists",
			this.verifyReceitaExists.bind(this)
		);

		this.store.events.subscribe("fileUpload", this.fileUpload.bind(this));
	}

	async createReceita(evt, data) {
		const res = await this.receitaMD.post(data);
		return await res.json();
	}

	async verifyReceitaExists(evt, data) {
		const res = await this.receitaMD.search(data);
		return await res.json();
	}

	async fileUpload(evt, data) {
		const form = new FormData(data.form[0]);

		form.append("file", data.file);

		const res = await this.receitaMD.sendAttachment(
			data.id,
			data.field,
			form
		);
		return res;
	}

	async getUserEmail() {
		const userJson = await $.getJSON("/no-cache/profileSystem/getProfile");
		if (userJson.IsUserDefined == true) {
			return userJson.Email;
		}
	}

	async _getProductData(skus) {
		const resPromises = skus.map(sku =>
			fetch(
				`/api/catalog_system/pub/products/search/?fq=productId:${sku}`
			)
		);

		const res = await Promise.all(resPromises);

		const data = await Promise.all(res.map(res => res.json()));

		return data.flat();
	}

	async _getUploadStatus(orderId, skuID) {
		//Validar se os arquivos já foram enviados
		const receitaSearch = {
			_fields: "receita,medicao",
			_where: `(orderID=${orderId}) AND (skuID=${skuID})`
		};

		const res = await this.receitaMD.search(receitaSearch);
		const arquivosSku = await res.json();
		try {
			return {
				receita:
					arquivosSku[0].receita != undefined ? "uploaded" : "idle",
				medicao:
					arquivosSku[0].medicao != undefined ? "uploaded" : "idle"
			};
		} catch (e) {
			return {
				receita: "idle",
				medicao: "idle"
			};
		}
	}

	async _hasReceita(productData, items) {
		let hasReceita = false;
		await productData.forEach(async product => {
			if (true) {
				hasReceita = true;

				product.imageOrder = product.items[0].images[0].imageTag;

				product.uploadStatus = await this._getUploadStatus(
					this.store.state.orderID,
					product.productId
				);

				this.store.commit("addProduct", product);
			}
			// if (product["Venda sob Prescrição Médica"]) {
			// 	product["Venda sob Prescrição Médica"].forEach((value) => {
			// 		if (value === "Sim") {
			//             hasReceita = true;
			//             let orderItem = items.filter(item => {
			//                 return item.productId == product.productId;
			//             })
			//             product.imageOrder = orderItem[0].imageUrl;
			//             this.store.commit("addProduct", product);
			// 		}
			// 	});
			// }
		});

		// return hasReceita;
		return true;
	}

	/**
	 * Verifica se os produtos possuiem prescrição médica e seta na store os produtos que precisam
	 * @param {(string|number)[]} productsIds ids dos produtos a serem verificados
	 */
	async verify(items) {
		const productData = await this._getProductData(items);
		return this._hasReceita(productData, items);
	}
}
