import { isSmallerThen768 } from "Helpers/MediasMatch";
import { isMobile } from "Helpers/utils";
import { alterarTamanhoImagemSrcVtex } from "Helpers/vtexUtils";
import { CHANGE_IMAGE_HIGHLIGHT, CHANGE_IMAGE_LIST } from "./EventType";

export default class ImageHighlight {
	constructor(store, container, options) {
		this._store = store;
		this._configurar(options);

		this.desenhar(container);

		this.eventos();
	}
	desenhar(container) {
		let _html = `<div class="images__highlight loading">
			<div class="text">Carregando...</div>
		</div>`;

		this._elemento = $(_html);
		this._elemento.appendTo(container);
	}

	eventos() {
		this._store.events.subscribe(
			CHANGE_IMAGE_LIST,
			this.atualizarLista.bind(this)
		);
		this._store.events.subscribe(
			CHANGE_IMAGE_HIGHLIGHT,
			this.atualizarDestaque.bind(this)
		);
		this.zoom();
	}

	atualizarLista(event, lista) {
		this.lista = lista.map(image => {
			image.src = alterarTamanhoImagemSrcVtex(
				image.src,
				this._options.width,
				this._options.height
			);
			return image;
		});

		let _html = this._buildHighlight(this.lista);
		this._elemento.html(_html);
		this.ativarSlide();
	}

	atualizarDestaque(event, imageDestaque) {
		if (!isSmallerThen768) {
			this._elemento
				.find(".images__highlight__list ul")
				.slick("slickGoTo", imageDestaque.index);
			setTimeout(() => {
				$("html, body.product").scrollTop(0);
			}, 1000);
		}
	}

	ativarSlide() {
		// ativa resumo
		this.resumoSlide();

		// ativa slide
		this._elemento
			.find(".images__highlight__list ul")
			.slick(this._options.slide);
	}

	resumoSlide() {
		if (this._options.resumo) {
			let $resumo = this._elemento.find(".images__resumo");
			let $slide = this._elemento.find(".images__highlight__list ul");

			$slide.on("afterChange", (event, slick) => {
				let { slideCount, currentSlide } = slick;
				$resumo.html(
					`<div> ${currentSlide + 1} de ${slideCount} </div>`
				);
			});
		}
	}

	zoom() {
		if (this._options.zoom && !isMobile()) {
			let offsetX = 0,
				offsetY = 0,
				x = 0,
				y = 0;
			let zoom = event => {
				var zoomer = event.currentTarget;
				try {
					event.originalEvent.offsetX
						? (offsetX = event.originalEvent.offsetX)
						: (offsetX = event.originalEvent.touches[0].pageX);

					event.originalEvent.offsetY
						? (offsetY = event.originalEvent.offsetY)
						: (offsetY = event.originalEvent.touches[0].pageY);

					x = (offsetX / zoomer.offsetWidth) * 100;
					x = x > 100 ? 100 : x;
					y = (offsetY / zoomer.offsetHeight) * 100;
					y = y > 100 ? 100 : y;

					zoomer.style.backgroundPosition = `${x}% ${y}%`;
				} catch (error) { }
			};

			this._elemento.on("mousemove", ".images__figure.zoom", zoom);
		}
	}

	_configurar(options) {
		// defaults
		if ($(window).width() < 810) {
			this._options = {
				active: true,
				width: 590,
				height: 590,
				zoom: true,
				resumo: false,
				slide: {
					arrows: false,
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					speed: 500,
					arrows: true,
					dots: true
				}
			};
		} else {
			this._options = {
				active: true,
				width: 800,
				height: 800,
				zoom: true,
				resumo: false,
				slide: {
					arrows: false,
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					speed: 500,
					arrows: false,
					dots: false
				}
			};
		}

		Object.assign(this._options, options);
	}

	_buildHighlight(images) {
		return `<div class="images__highlight__list">
			<ul>
			${images
				.map(
					image =>
						`<figure class="images__figure zoom"
					style="background-image: url(${alterarTamanhoImagemSrcVtex(
							image.src,
							1000,
							1000
						)})">

					<img style="background-color: #fff" itemprop="image" src="${image.src}" title="${image.label}"
						width="${this._options.width}px" height="${this._options.height}px" />
				</figure> `
				)
				.join("")}
			</ul>
			<div class="images__resumo"></div>
		</div>`;
	}
}
