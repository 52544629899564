import { html, render } from "lit-html";
import { inserirTamanhoImagemSrcVtex } from "Helpers/vtexUtils";

export default class ReceitaView {
	constructor(service, element) {
		this.store = service.store;
		this.element = element;

		this.render();

		this.store.events.subscribe("stateChange", this.render.bind(this));
	}

	handleSelect(productJson) {
		this.store.commit("setProductSelected", null);
		this.store.commit("setProductSelected", productJson);
	}

	isProductSelected(product) {
		const state = this.store.state;
		const productSelected = state.productSelected;
		if (productSelected != null) {
			if (productSelected.productId == product.productId) {
				return "isSelected";
			}
		}
		return "";
	}

	itemsList(produto) {
		const imgUrl = $(
			inserirTamanhoImagemSrcVtex(produto.imageOrder, 300, 300)
		).attr("src");
		return html`
			<div class="item" @click=${() => this.handleSelect(produto)}>
				<div class="item__image">
					<div
						class="item__selected ${this.isProductSelected(
							produto
						)}"
					></div>
					<img src=${imgUrl} />
				</div>
				<div class="item__name">
					<p>${produto.brand}</p>
					<span> ${produto.productName} </span>
				</div>
			</div>
		`;
	}

	customNameFileInput(e) {
		$(e.target)
			.siblings("span")
			.text(e.target.files[0].name);
	}

	uploadStatus(status) {
		switch (status) {
			case "uploading":
				return html`
					<p>Carregando o seu arquivo...</p>
					<div class="loading-bar">
						<div class="bar"></div>
					</div>
				`;
			case "uploaded":
				return html`
					<i class="sprite sprite-envio-concluido"></i>
					<span> O upload da sua receita foi concluído! </span>
				`;
			case "error":
				return html`
					<i class="sprite sprite-falha-envio"></i>
					<span> Ops, algo deu errado! </span>
				`;
			case "empty":
				return html`
					<i class="sprite sprite-falha-envio"></i>
					<span> Adicione a imagem primeiro! </span>
				`;
			case "invalid-type":
				return html`
					<i class="sprite sprite-falha-envio"></i>
					<span> Tipo de arquivo não suportado! </span>
				`;
			default:
				break;
		}
	}

	uploadFile(e, store) {
		e.preventDefault();
		store.dispatch("uploadFile", e);
	}

	uploadBuild(store) {
		const { state } = store;
		if (state.productSelected != null) {
			return html`
				<div
					data-id="${state.productSelected.productId}"
					class="receita__order__upload"
				>
					<div class="upload__receita">
						<i class="sprite sprite-receita"></i>
						<h4>Envie sua receita</h4>
                        <div class="upload__text-option">
                            <p>
                                Clique abaixo para nos enviar a foto de sua receita!
                                <br />
                                Só serão aceitos arquivos nos formatos JPG e PNG,
                                com um tamanho máximo de 15MB.
                            </p>
                        </div>
						<form id="teste" data-form="receita">
							<label for="receita-upload" class="upload__label">
								<input
									class="upload__input"
									type="file"
									name="receita-upload"
									id="receita-upload"
									data-id="${state.productSelected.productId}"
									@change=${this.customNameFileInput.bind(
										this
									)}
								/>
								<span> Fazer upload da imagem </span>
							</label>
							<div class="upload__status">
								${this.uploadStatus(
									state.productSelected.uploadStatus.receita
								)}
							</div>
							<input
								class="upload__document"
								type="submit"
								value="Enviar"
								@click=${e => this.uploadFile(e, store)}
							/>
						</form>
					</div>
					<div class="upload__medicao">
						<i class="sprite sprite-medicao"></i>
						<h4>Envie sua foto de medição</h4>
                        <div class="upload__text-option">
                            <p>
                                Clique abaixo para nos enviar a sua foto de medição!
                                <br />
                                A foto de medição é utilizada para medirmos
                                corretamente a distância dos seus olhos e fazermos a
                                lente especialmente para você. <br />
                                Clique Aqui para ver uma imagem de como deve ser a
                                foto. <br />
                                Só serão aceitos arquivos nos formatos JPG e PNG,
                                com um tamanho máximo de 15MB.
                            </p>
                            <button id="medicao-popup">
                                <p> Clique aqui para visualizar como tirar a foto </p>
                            </button>
                        </div>
						<form data-form="medicao">
							<label for="medicao-upload" class="upload__label">
								<input
									class="upload__input"
									type="file"
									name="medicao-upload"
									id="medicao-upload"
									data-id="${state.productSelected.productId}"
									@change=${e => this.customNameFileInput(e)}
								/>
								<span> Fazer upload da imagem </span>
							</label>
							<div class="upload__status">
								${this.uploadStatus(
									state.productSelected.uploadStatus.medicao
								)}
							</div>
							<input
								class="upload__document"
								type="submit"
								value="Enviar"
								@click=${e => this.uploadFile(e, store)}
							/>
						</form>
					</div>
				</div>
			`;
		}
	}

	build() {
		const { store } = this;
		const { state } = store;
		return html`
			<div class="receita__order__wrapper">
				<div class="receita__order__title">
					<h3>Meus pedidos</h3>
				</div>
				<div class="receita__order__list">
					${state.products.map(product => {
						return this.itemsList(product);
					})}
				</div>
				${this.uploadBuild(store)}
			</div>
		`;
	}
	render() {
		render(this.build(), this.element);
	}
}
