import Components from "Lib/ComponentesProduto";
import Accessory from "./Accessory";
import { formatCurrency } from "Helpers/vtexUtils";

export default class Accessories {
	constructor() {
		this.start();
	}

	async start() {
		this.creator();
		await this.createAccessories();
		this.selectors();
		this.events();
	}

	creator() {
		this.commonStore = new Components.ComponentStore({
			state: {
				stores: {}
			},

			mutations: {
				addStore(state, { key, store }) {
					state.stores[key] = store;
				}
			}
		});

		this.selectedSkus = [];
	}

	async createAccessories() {
		try {
			const accessories = await this.getAccessories();

			accessories.forEach(accessory => {
				new Accessory({
					parent: ".productAccessories__accessories",
					skuJson: accessory,
					commonStore: this.commonStore
				});
			});
		} catch (error) {
			console.warn(
				"A prateleira de acessórios não será criada\n",
				error.message
			);
		}
	}

	async getAccessories() {
		const { productId } = window.skuJson;
		const url = `/api/catalog_system/pub/products/crossselling/accessories/${productId}`;

		const accessoriesData = await $.ajax({
			url: url,
			type: "GET"
		});

		if (accessoriesData.length) {
			const promises = [];

			accessoriesData.forEach(accessoryData => {
				const productId = accessoryData.productId;

				promises.push(
					vtexjs.catalog.getProductWithVariations(productId)
				);
			});

			const accessories = await Promise.all(promises);
			return accessories;
		} else {
			$(".productAccessories").remove();
			throw new Error("Acessórios não cadastrados para este produto");
		}
	}

	selectors() {
		this.checkbox = $(".accessory__checkbox");
		this.totalValue = $(".productAccessories__totalValue");
		this.buyButton = $(".productAccessories__buyButton");
	}

	events() {
		this.checkbox.change(this.setSelectedSkus.bind(this));
		this.buyButton.click(this.buySkus.bind(this));
	}

	setSelectedSkus(event) {
		const checkbox = $(event.target);
		const productId = checkbox.parents(".accessory").data("product-id");

		if (checkbox.is(":checked")) {
			const productStore = this.commonStore.state.stores[productId];
			const sku = productStore.state.selectedSku;

			this.selectedSkus.push({
				key: productId,
				sku
			});
		} else {
			this.selectedSkus = this.selectedSkus.filter(
				selectedSku => selectedSku.key !== productId
			);
		}

		this.calculateTotalValue();
	}

	calculateTotalValue() {
		if (this.selectedSkus.length) {
			this.buyButton.addClass("isActive");

			const total = this.selectedSkus.reduce(
				(accumulator, selectedSku) => {
					return accumulator + selectedSku.sku.bestPrice;
				},

				0
			);

			const formattedTotal = formatCurrency(total / 100);
			this.totalValue.text(formattedTotal);
		} else {
			this.buyButton.removeClass("isActive");
			this.totalValue.text("");
		}
	}

	async buySkus() {
		if (this.selectedSkus.length) {
			try {
				const items = this.selectedSkus.map(selectedSku => ({
					id: selectedSku.sku.sku,
					quantity: 1,
					seller: 1
				}));

				await vtexjs.checkout.addToCart(items, null, 1);
				this.createPopup();
			} catch (error) {
				console.warn(
					"Erro ao adicionar os acessórios ao carrinho\n",
					error.message
				);
			}
		}
	}

	createPopup() {
		const popup = $("<div />", { class: "productAccessories__popup" });

		popup.append(`
			<span>Produtos adicionados ao carrinho.</span>
			<a href="/checkout">Ver carrinho</a>
		`);

		$("body").append(popup);
		popup.fadeIn();

		setTimeout(() => popup.fadeOut(), 5000);
		setTimeout(() => popup.remove(), 10000);
	}
}
