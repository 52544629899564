import Components from "Lib/ComponentesProduto";
import ProductModules from "App/components/ProductModules";
import { alterarTamanhoImagemSrcVtex } from "Helpers/vtexUtils";

export default class Accessory {
	constructor({ parent, skuJson, commonStore }) {
		this.parent = $(parent);
		this.skuJson = skuJson;
		this.productId = this.skuJson.productId;
		this.commonStore = commonStore;

		this.render();
		this.initModules();
	}

	render() {
		const sku = this.skuJson.skus[0];

		const accessory = `
			<li class="accessory" data-product-id="${this.productId}">
				<div class="accessory__item">
					<div class="accessory__image">
						<img src="${alterarTamanhoImagemSrcVtex(sku.image, 300, 300)}" />
					</div>

					<div class="accessory__info">
						<div class="accessory__name">
							${this.skuJson.name}
						</div>

						<div id="price-${this.productId}" class="accessory__price"></div>
					</div>
				</div>

				<fieldset>
					<input
						id="check-${this.productId}"
						class="accessory__checkbox"
						type="checkbox"
					>

					<label for="check-${this.productId}"></label>
				</fieldset>
			</li>
		`;

		this.parent.append(accessory);
	}

	initModules() {
		const store = new Components.ComponentStore();

		new ProductModules({
			priceSelector: `#price-${this.productId}`,
			skuJson: this.skuJson,
			store
		});

		this.commonStore.commit("addStore", {
			key: this.productId,
			store
		});
	}
}
