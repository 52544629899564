import { GetImgHover } from "App/components/GetImgHover";
import { isSmallerThen768 } from "Helpers/MediasMatch";

export default class Categoria {
	constructor() {
		this.redirect();
		this.atualizaFiltro();
		this.filterActive1();
		this.contadorFiltro();
		this.filterClosed();
		this.hidenFiltro();
		this.gridmobile();
		GetImgHover();
		this.searchBrand();
		this.limparFilter();
		this.altImg();
	}

	redirect() {
		if (window.location.pathname.includes("/lente/")) {
			window.location.href =
				"/oculos/receituario?O=OrderByReleaseDateDESC";
		}
	}
	filterActive1() {
		$(".search-result-data.container").hide();
		$("fieldset div label").click(function() {
			if ($(this).find("sr__selected")) {
				$(".search-result-data.container").show();
			}
		});
	}
	hidenFiltro() {
		$(".search-result-data-filtered-clear a").click(function() {
			$(".search-result-data.container").hide();
		});
	}
	atualizaFiltro() {
		$(window).on("load", function() {
			if ($(".search-multiple-navigator label.sr_selected").length >= 1) {
				$(".search-multiple-navigator label").removeClass(
					"sr_selected"
				);
				$(".search-multiple-navigator input").prop("checked", false);
				$(".search-multiple-navigator input").trigger("change");
			}
			$(".search-result-data-filtered-clear span").before(
				$(".opcoes-resultado .open-filter .count-filter")
			);
		});
	}
	contadorFiltro() {
		$(window).on("load", function() {
			$(
				".search-multiple-navigator label, .limpar-filtro, .search-result-data-filtered-clear a"
			).click(function() {
				let countFiltro = $(".search-result-data-filtered-items")[0]
					.children.length;

				if (!countFiltro == 0) {
					$(".count-filter").html(
						`(${countFiltro.toString().padStart(2, "0")})`
					);
				} else {
					$(".count-filter").html("");
				}
			});
		});
	}
	filterClosed() {
		$(".search-filter fieldset").each(function() {
			$(this).addClass("closed");
		});
	}
	gridmobile() {
		$("body").on("click", ".content--grid-shelf_mob span", function() {
			if ($(this).hasClass("two-per-row")) {
				$(this)
					.addClass("active")
					.next("span")
					.removeClass("active");
				$(".main .prateleira > ul")
					.addClass("two-per-row")
					.removeClass("one-per-row");
			} else if ($(this).hasClass("one-per-row")) {
				$(this)
					.addClass("active")
					.parent(".content--grid-shelf_mob")
					.find(".two-per-row")
					.removeClass("active");
				$(".main .prateleira > ul")
					.addClass("one-per-row")
					.removeClass("two-per-row");
			}
		});
		$(".search-title__content").before($(".search-single-navigator h4"));

		$("body").on(
			"click",
			".search-main-content .search-filter .close-filter.mobile",
			function() {
				$("aside.search-filter").toggleClass("active");
				$(".filter-overlay,.button-container").toggleClass("active");
			}
		);
	}
	searchBrand() {
		if ($("body").hasClass("marca")) {
			var e = $(".search-results .vitrine  ul article")
				.eq(0)
				.attr("data-brand");

			if (e === "DII COLLECTION") {
				this.buildBrandBanner();
				return;
			}

			$.ajax({
				url:
					'https://api.vtexcrm.com.br/oticasdiniz/dataentities/MC/search?_fields=id,id_marca,logo,descricao&_where=marca="' +
					e +
					'"',
				type: "GET"
			}).always(function(e) {
				$(".search-title .container").before(
					'<div class="brand-description"><img src="https://api.vtex.com/oticasdiniz/dataentities/MC/documents/' +
						e[0].id +
						"/logo/attachments/" +
						e[0].logo +
						'" style="  margin: 5px auto; display: block;  max-width: 60%;border-bottom: 1px solid #eee;"/><p>' +
						e[0].descricao +
						"</p></div>"
				);
			});
		}
	}

	async buildBrandBanner() {
		const req = await fetch(
			`https://api.vtexcrm.com.br/oticasdiniz/dataentities/MC/search?_fields=id,banner_img,banner_img_mobile,banner_text_subtitle,banner_text_title&_where=marca="DII COLLECTION"`
		);
		const data = await req.json();

		const brand = {
			id: data[0].id,
			image: data[0].banner_img,
			imageMobile: data[0].banner_img_mobile,
			title: data[0].banner_text_title,
			subtitle: data[0].banner_text_subtitle
		};

		let html = "";
		html += `<section class="brand-banner">`;
		html += `<img class="brand-banner-img" src="
					${
						isSmallerThen768
							? `https://api.vtex.com/oticasdiniz/dataentities/MC/documents/${brand.id}/banner_img_mobile/attachments/${brand.imageMobile}`
							: `https://api.vtex.com/oticasdiniz/dataentities/MC/documents/${brand.id}/banner_img/attachments/${brand.image}`
					}" />`;
		html += `<div class="brand-banner-text">`;
		html += `<div class="brand-banner-title">${brand.title}</div>`;
		html += `<div class="brand-banner-subtitle">${brand.subtitle}</div>`;
		html += `</div>`;
		html += `</section>`;

		$(html).insertBefore(".search-title");
	}

	limparFilter() {
		$(".search-multiple-navigator label").on("click", function() {
			if ($(".search-multiple-navigator label.sr_selected").length >= 1) {
				$(".search-result-data.container").css("display", "none");
			} else {
				$(".search-result-data.container").css("display", "block");
			}
		});
	}
	altImg() {
		const imgs = $("body.search .shelf ul li img");
		setTimeout(() => {
			for (i = 0; i < imgs.length; i++) {
				const nameCategoria = $(imgs[i])
					.parent(".shelf-image.addImg")
					.siblings("h2")
					.text();
				imgs[i].alt = nameCategoria;
			}
		}, 3000);

		setTimeout(() => {
			for (i = 0; i < imgs.length; i++) {
				const nameCategoria = $(imgs[i])
					.parent(".shelf-image.addImg")
					.siblings("h2")
					.text();
				const imgsHover = $(imgs[i])
					.siblings(".content-hover")
					.find(".img-hover");
				if (imgsHover) {
					imgsHover.attr("alt", nameCategoria);
				}
			}
		}, 3000);
	}
}
