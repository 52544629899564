import {
	LentesContatoModule
} from "App/components/Lentes-contato/lentes-contato-module";


export class LentesContatoPage {

	constructor() {
		this.isMobile = window.matchMedia("(max-width:767px)").matches;
		this.init();
	}

	init() {
		this.compreEGanhe();
		new LentesContatoModule();
		this.retrieveProduct().then(() => {
			if (this.product) this.customBanner();
		});
	}


	async compreEGanhe() {
		const descontos = [{
				comprando: 3,
				ganhe: 1
			},
			{
				comprando: 8,
				ganhe: 2
			},
			{
				comprando: 12,
				ganhe: 4
			}
		];

		const buildElement = (desconto) => {
			const preco = _preco;
			const total = desconto.comprando * preco;
			const totalComDesconto = (desconto.comprando - desconto.ganhe) * preco;
			const percentualDesconto = (totalComDesconto / total) * 100;
			const valorPorCaixa = preco * (percentualDesconto / 100);
			const el = `
				<div class="desconto">
					<div class="desconto__percentual">Economia de ${(100-percentualDesconto).toFixed(2)}%</div>
					<div class="desconto__caixas">Comprando ${desconto.comprando} CAIXAS</div>
					<div class="desconto__caixas-gratis">${desconto.ganhe} ${desconto.ganhe > 1? "caixas são": "caixa é"} de Graça</div>
					<div class="desconto__valor-caixa">Valor por caixa R$${valorPorCaixa.toFixed(2).replace('.', ',')}</div>
				</div>
			`;
			return el;
		}

		const _preco = window.skuJson.skus[0].bestPrice / 100;
		const html = descontos.map(buildElement.bind(this)).join('');
		$('.compre-e-ganhe .compre-e-ganhe__descontos').html(html);
	}

	async retrieveProduct() {
		const url = `/api/catalog_system/pub/products/search${window.location.pathname.toLowerCase()}`;

		if (this.product) return this.product;

		try {
			let data = await $.ajax({
				url: url,
				type: "GET",
			});

			this.product = data[0];

			return this.product;
		} catch (error) {
			return console.warn(error);
		}
	}

	customBanner() {
		const customBanner = this.product["Banner"];
		const customBannerMobile = this.product["Banner Mobile"];

		if (customBanner) {
			let img = $("<img/>", {
				alt: "Banner Lente",
				src: "/arquivos/" + customBanner,
				class: "banner-desktop"
			});

			$(".descricao-completa__banner").append(img);
		}
		if(customBannerMobile){
			let img = $("<img/>", {
				alt: "Banner Lente",
				src: "/arquivos/" + customBanner,
				class: "banner-mobile"
			});

			$(".descricao-completa__banner").append(img);
		}
	}

}
