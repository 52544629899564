import { alterarTamanhoImagemSrcVtex } from "Helpers/vtexUtils";

export default class Minicart {
	createMiniCartStructure(element) {
		const structure = `
			<div class="mini-cart-container">
				<div class="mini-cart-header">
					<i class="sprite sprite-cadeado"></i>
					<div class="minicart-title">
						<span>SACOLA</span>
						<span class="minicart-total-items"></span>
					</div>
					<button class="btn-close" aria-label="fechar carrinho">
						fechar
					</button>
				</div>

				<div class="mini-cart-main">
					<div class="wait-screen"></div>
					<ul class="product-list"></ul>
				</div>
				<div class="mini-cart-footer">
					<div class="totals-container">

						<div class="total">
							<span>Total:</span> <span class="total-values"><strong>R$<span class="value">00,00</span></strong><span class="value-parcelas"></span></span>
						</div>
					</div>
					<button class="continue-buying" aria-label="Continuar comprando">
						Continuar comprando
					</button>
					<a class="finish-order" href="/checkout/#cart">
						COMPRAR
					</a>
				</div>
			</div>
			<div class="mini-cart-overlay"></div>
		`;
		$(element).html(structure);
	}

	listItems() {
		let orderItems = items => {
			$(".mini-cart-container .product-list").empty();

			for (var i = 0; i < items.length; i++) {
				var price = parseFloat(items[i].sellingPrice / 100).toFixed(2);
				price = "R$ " + price.replace(".", ",");

				if (items[i].sellingPrice == 0) {
					price = "Grátis";
				}

				var orderItem = `
					<li id="${i + "-" + items[i].uniqueId}" data-refId="${
					items[i].productRefId
				}" class="product">
						<div class="product-image">
							<a href="${items[i].detailUrl}" title="${items[i].name}">
								<img src="${alterarTamanhoImagemSrcVtex(items[i].imageUrl, 127, 120)}" alt="${
					items[i].name
				}">
							</a>
						</div>
						<div class="product-options">
							<div class="product-info">
								<div class="name">${items[i].name}</div>
								<div class="product-price">${price}</div>
								<div class="product-qtd">
									<button class="remove-from-cart" aria-label="Remover um item">-</button>
									<span class="value">${items[i].quantity}</span>
									<button class="add-to-cart" aria-label="Adicionar um item">+</button>
								</div>
							</div>
							<div class="product-remove">
								<button class="remove" aria-label="Remover produto">Remover</button>
							</div>
						</div>
					</li>
				`;

				$(".mini-cart-container .product-list").append(orderItem);
			}

			this.addOneToCart();
			this.removeOneFromCart();
			this.removeFromCart();
			this.updateCartTotals();
		};

		let emptyMessage = () => {
			$(".mini-cart-container .product-list").empty();
			$(".mini-cart-footer").empty();
			let emptyMesssage = `
				<li class="empty-message">

						<strong>
							Sua sacola está vazia
						</strong>
						<button class="continue-buying" aria-label="Continuar comprando">
							Continuar comprando
						</button>

				</li>
			`;

			$(".mini-cart-container .product-list").append(emptyMesssage);

			this.updateCartTotals();
		};

		try {
			var items = window.vtexjs.checkout.orderForm.items;
			$(".mini-cart-container").removeClass("have-item");

			if (items.length > 0) {
				orderItems(items);
				$(".mini-cart-container").addClass("have-item");
			} else {
				emptyMessage();
			}
		} catch (e) {
			emptyMessage();
			console.warn("couldnt list items. " + e.message);
		}
	}

	async insertStallment() {
		let installmentsOptions = await window.vtexjs.checkout.orderForm
			.paymentData.installmentOptions;

		let numParcela =
			installmentsOptions[0].installments[
				installmentsOptions[0].installments.length - 1
			].count;

		if (numParcela) {
			const valorParcela =
				installmentsOptions[0].installments[
					installmentsOptions[0].installments.length - 1
				].value;
			const valorParcelaFormatado = parseFloat(valorParcela / 100)
				.toFixed(2)
				.replace(".", ",");
			if (numParcela && valorParcelaFormatado) {
				const frase = `<p> em até ${numParcela}X de R$${valorParcelaFormatado}</p> `;
				$(".mini-cart-footer .value-parcelas").html(frase);
			}
		}
	}

	updateCartTotals() {
		var totalQtd = 0;
		var totalPrice = 0;

		try {
			var items = window.vtexjs.checkout.orderForm.items;

			var valorSubtotalCompraEmCentavos = 0;
			var valorDescontosEmCentavos = 0;

			if (window.vtexjs.checkout.orderForm.totalizers.length > 0) {
				valorSubtotalCompraEmCentavos =
					window.vtexjs.checkout.orderForm.totalizers[0].value;

				if (window.vtexjs.checkout.orderForm.totalizers[1]) {
					if (
						window.vtexjs.checkout.orderForm.totalizers[1].id ==
						"Discounts"
					) {
						valorDescontosEmCentavos =
							window.vtexjs.checkout.orderForm.totalizers[1]
								.value;
					}
				}
			}

			totalPrice +=
				(valorSubtotalCompraEmCentavos + valorDescontosEmCentavos) /
				100;
			totalPrice = parseFloat(totalPrice).toFixed(2);

			if (isNaN(totalPrice)) {
				totalPrice = " ";
			} else {
				totalPrice = totalPrice.replace(".", ",");
			}

			$(".mini-cart-container .product-list .product").each(function() {
				var productId = $(this).attr("id");

				for (var i = 0; i < items.length; i++) {
					if (i + "-" + items[i].uniqueId == productId) {
						var qtd = parseInt(items[i].quantity);
						totalQtd += qtd;
						$(this)
							.find(".product-qtd .value")
							.text(qtd);
						break;
					}
				}
			});
			$(".minicart-total-items").text(totalQtd);

			if (totalQtd === 0) {
				$(".mini-cart-footer .value-parcelas").text("");
				$(".minicart-total-items").text("");
			} else if (totalQtd === 1) {
				$(".minicart-total-items").text(
					`${String(totalQtd).padStart(2, "0")}`
				);
			} else {
				this.insertStallment();
				$(".minicart-total-items").text(
					`${String(totalQtd).padStart(2, "0")}`
				);
			}
		} catch (e) {
			console.warn("couldnt update cart info. " + e.message);
		}

		$(".mini-cart-footer .total .value").text(totalPrice);
		$(".mini-cart-footer .qtd-items .value").text(totalQtd);
		$(".cartInfoWrapper .amount-items-em").text(totalQtd);

		if (!totalQtd > 0) {
			$(".mini-cart-footer .value-parcelas").html("");
		} else {
			this.insertStallment();
		}
	}
	updateOrderForm(productId, qtd) {
		try {
			let $this = this;
			var teste = false;
			window.vtexjs.checkout
				.getOrderForm()
				.then(orderForm => {
					$(".mini-cart-container .wait-screen").addClass("active");
					for (var i = 0; i < orderForm.items.length; i++) {
						if (
							i + "-" + orderForm.items[i].uniqueId ==
							productId
						) {
							var itemIndex = i;
						}
					}

					var updateItem = {
						index: itemIndex,
						quantity: qtd
					};

					if (updateItem.quantity == 0) {
						teste = true;
					}
					return window.vtexjs.checkout.updateItems(
						[updateItem],
						null,
						false
					);
				})
				.done(function(orderForm) {
					$(".mini-cart-container .wait-screen").removeClass(
						"active"
					);
				})
				.always(orderForm => {
					$this.listItems();
					if (teste) {
						console.log(orderForm);
						if (orderForm.items.length == 1) {
							let nameCart = $(
								".mini-cart-container .product-list .product-info .name"
							).text();
							if (
								nameCart.indexOf("LENTE") > -1 ||
								nameCart.indexOf("Lente") > -1
							) {
								$(
									".mini-cart-container .product-list .product-remove button"
								).trigger("click");
							}
						} else if (orderForm.items.length > 1) {
							let totalLentes = 0;
							let totalArm = 0;

							$.each(orderForm.items, function(
								indexInArray,
								value
							) {
								// productCategoryIds
								if (
									value.productCategoryIds.indexOf("/1/11/") >
									-1
								) {
									totalArm = totalArm + 1;
								}
								if (
									value.productCategoryIds.indexOf("/2/") > -1
								) {
									totalLentes = totalLentes + value.quantity;
								}
							});

							if (totalLentes > totalArm) {
								let qntToRemove = totalLentes - totalArm;
								$(".mini-cart-container .product-list li").each(
									function(index, element) {
										// element == this
										let nameProd = $(element)
											.find(".product-info .name")
											.text()
											.toUpperCase();

										if (qntToRemove > 0) {
											if (
												nameProd.indexOf("LENTE") >
													-1 ||
												nameProd.indexOf("LENS") > -1
											) {
												const currentQnt = $(element)
													.find(
														".product-info .product-qtd .value"
													)
													.text();
												if (currentQnt < qntToRemove) {
													qntToRemove =
														qntToRemove -
														currentQnt;
													$(element)
														.find(
															".product-remove button"
														)
														.trigger("click");
												} else if (
													currentQnt > qntToRemove
												) {
													$this.updateProductQntProduct(
														index,
														currentQnt - qntToRemove
													);
													qntToRemove = 0;
												} else {
													$(element)
														.find(
															".product-remove button"
														)
														.trigger("click");
													qntToRemove = 0;
												}
											}
										}
									}
								);
							}
						}
					}
				});
		} catch (e) {
			console.warn("couldnt update order form. " + e.message);
		}
	}
	addOneToCart() {
		$(".mini-cart-container .add-to-cart").click(e => {
			var qtd = $(e.target).siblings(".value");
			var productId = $(e.target)
				.parents(".product")
				.attr("id");

			if ($.isNumeric(qtd.text())) {
				var valueQtd = parseInt(qtd.text());

				valueQtd += 1;
				this.updateOrderForm(productId, valueQtd);
			} else {
				this.updateOrderForm(productId, 1);
			}
		});
	}
	removeOneFromCart() {
		$(".mini-cart-container .remove-from-cart").on("click", e => {
			var qtd = $(e.target).siblings(".value");
			var productId = $(e.target)
				.parents(".product")
				.attr("id");

			if ($.isNumeric(qtd.text())) {
				var valueQtd = parseInt(qtd.text());
				if (valueQtd > 1) {
					valueQtd -= 1;
					this.updateOrderForm(productId, valueQtd);
				}
			} else {
				this.updateOrderForm(productId, 1);
			}
		});
	}

	updateProductQntProduct(index, qnt) {
		window.vtexjs.checkout
			.getOrderForm()
			.then(orderForm => {
				$(".mini-cart-container .wait-screen").addClass("active");
				var updateItem = {
					index: index,
					quantity: qnt
				};
				return vtexjs.checkout.updateItems([updateItem], null, false);
			})
			.done(() => {
				$(".mini-cart-container .wait-screen").removeClass("active");
				this.listItems();
			});
	}
	removeFromCart() {
		$(".mini-cart-container .product-remove .remove").click(e => {
			var productId = $(e.target)
				.parents(".product")
				.attr("id");
			this.updateOrderForm(productId, 0);
			this.listItems();
		});
	}
	configureEvents(openBtn) {
		var openMinicart = () => {
			this.listItems();
			$(".mini-cart-container")
				.parent()
				.toggleClass("show-mini-cart");
			$(".mini-cart-overlay").toggleClass("active");
			$(".mini-cart")
				.parents("header")
				.toggleClass("mini-cart-open");
		};

		var closeMinicart = function() {
			$(".mini-cart-container")
				.parent()
				.removeClass("show-mini-cart");
			$(".mini-cart-overlay").removeClass("active");
			$(".mini-cart")
				.parents("header")
				.removeClass("mini-cart-open");
		};

		$(document).on("click", openBtn, function(e) {
			event.preventDefault();
			$(openBtn).toggleClass("active");
			openMinicart();
		});

		$(document).on("open-minicart", openMinicart);

		$(document).on(
			"click",
			".mini-cart-container .btn-close, .mini-cart-container .continue-buying, .mini-cart-overlay",
			function() {
				closeMinicart();
				$(openBtn).toggleClass("active");
			}
		);
	}

	constructor(ctx, element) {
		try {
			window.window.vtexjs.checkout.getOrderForm();
		} catch (error) {
			console.warn("vtexjs not found.");
		}
		this.openMinicartBtnBuy();
		this.createMiniCartStructure(element);
		this.configureEvents(
			"header .htmlMinicart, .footer-minicart, .popup-add-cart .cart"
		);
	}

	openMinicartBtnBuy() {
		this.events();
	}

	events() {
		this.buyButton = $(".product-buy .buy-button");
		const bodyClass = $("body.lente-customizada").length;
		const bodyClassLente = $("body.lente-contato.product").length;

		if (bodyClass < 1 && bodyClassLente < 1) {
			this.buyButton.click(this.buyProduct.bind(this));
		}
	}

	buyProduct(event) {
		event.preventDefault();

		const buttonHref = $(event.target).attr("href");
		const sku = buttonHref.match(/(sku=)(\d*)(&)/)[2];
		const quantity = buttonHref.match(/(qty=)(\d*)(&)/)[2];
		const seller = buttonHref.match(/(seller=)(\d*)(&)/)[2];
		const sallesChannel = buttonHref.match(/(sc=)(\d*)/)[2];

		this.addToCart({
			sku,
			quantity,
			seller,
			sallesChannel
		});
	}

	addToCart({ sku, quantity, seller, sallesChannel }) {
		try {
			window.vtexjs.checkout
				.addToCart(
					[
						{
							id: sku,
							quantity: quantity,
							seller: seller
						}
					],
					null,
					sallesChannel
				)
				.done(() => {
					$(document).trigger("open-minicart");
					$(".product .pop-up").toggleClass("ativo");
				});
		} catch (error) {
			console.log("Erro ao adicionar o produto ao carrinho.");
		}
	}
}
