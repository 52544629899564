export const SC_ITEMS = "m3-otica-diniz-products";
export const TEM_RECEITA = "m3-otica-diniz-receita";
export default class updateSkusStorage {
    constructor() {
        if(!$('body').hasClass('body-checkout-confirmation')){
            vtexjs.checkout.getOrderForm()
                .done((orderForm) => {
                    this.updateSkusStorage(orderForm);
                });
            this.events();
        }
    }

    events(){
        $(window).on('orderFormUpdated.vtex', (evt, orderForm) => {
            this.updateSkusStorage(orderForm);
        });
    }

    updateSkusStorage(orderForm){
        window.localStorage.setItem(SC_ITEMS, JSON.stringify(orderForm.items));
        // const teste = Object.keys(orderForm.items).some(itemCategory => 
        //     itemCategory == 3    
        // );
        // if(teste){
        //     console.log("bom")
        // }
        console.log(JSON.parse(window.localStorage.getItem(SC_ITEMS)))
    }
}